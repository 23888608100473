import { useQuery } from "@tanstack/react-query";
import { TaskGenerationQueryKeyString } from "../enums/taskgenerationQueryKey.enum";
import { taskGenerationSvc } from "../services/taskgeneration.service";

export const useGetTasks = (categoryId: string, sectionId: string) => {
  return useQuery({
    queryKey: [TaskGenerationQueryKeyString.Tasks, categoryId, sectionId], // Include categoryId & sectionId in the queryKey
    queryFn: () => taskGenerationSvc.fetchTasks(categoryId, sectionId), // Pass the parameters here
    enabled: !!categoryId && !!sectionId, // Only run query if both IDs exist
  });
};
