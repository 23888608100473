import { useQuery } from "@tanstack/react-query";
import { superAdminQueryKeyString } from "../enums/superadminQueryKey.enum";
import { superAdminSvc } from "../services/superadmin.service";
import { Filters } from "../types/IDashboard.Interface";

export const useSuperAdminDashboard = (filters: Filters) => {
  return useQuery({
    queryKey: [superAdminQueryKeyString.Dashboard, filters],
    queryFn: () => superAdminSvc.fetchDashboard(filters),
  });
};
