import React, { useState, useRef, useEffect } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa"; // Import FontAwesome icons
import { IOptionS } from "../../types/ICenterSetup.interface";
import RequiredAsterisk from "../../../shared/components/ui/RequiredAsterisk";

interface DropdownProps {
  times: IOptionS[];
  label: string;
  placeholder: string;
  value: string;
  onSelectTime: (selectedTime: string) => void;
  required?: boolean;
  disabled?: boolean;
}

const TimeDropdown: React.FC<DropdownProps> = ({
  times,
  label,
  placeholder,
  value,
  onSelectTime,
  required = false,
  disabled = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTime, setSelectedTime] = useState<string | null>(null);
  const [selectedPeriod, setSelectedPeriod] = useState<string | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const amPmOptions = ["AM", "PM"];

  const toggleDropdown = () => setIsOpen((prev) => !prev);
  const handleTimeChange = (value: string) => {
    setSelectedTime(value);
    if (selectedPeriod) {
      onSelectTime(`${value} ${selectedPeriod}`); // Send time and period to parent
    }
  };

  const handlePeriodChange = (period: string) => {
    setSelectedPeriod(period);
    if (selectedTime) {
      onSelectTime(`${selectedTime} ${period}`); // Send time and period to parent
    }
  };

  useEffect(() => {
    if (!value) return;
    const [time, period] = value.split(" ");
    setSelectedTime(time);
    setSelectedPeriod(period);
  }, [value]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (selectedTime && selectedPeriod) {
      setIsOpen(false);
    }
  }, [selectedTime, selectedPeriod]);

  return (
    <div className="relative w-full" ref={dropdownRef}>
      <label className="text-base text-secondaryVariant">
        {label}
        {required && <RequiredAsterisk />}
      </label>
      <div className="mt-1">
        <button
          type="button"
          onClick={() => {
            !disabled && toggleDropdown();
          }}
          className={`w-full bg-white border border-gray-300 rounded-lg shadow-sm px-4 py-3.5 text-left focus:outline-none flex justify-between items-center ${disabled ? "opacity-50 cursor-default" : "cursor-pointer"}`}
        >
          <span>
            {selectedTime
              ? selectedTime + (selectedPeriod ? ` ${selectedPeriod}` : "")
              : placeholder}
          </span>
          <span className="ml-2">
            {isOpen ? (
              <FaChevronUp className="text-gray-600" />
            ) : (
              <FaChevronDown className="text-gray-600" />
            )}
          </span>
        </button>
        {isOpen && (
          <div className="absolute z-10 mt-1 w-[221px] right-0 bg-white border border-gray-300 rounded-lg shadow-lg p-2">
            <div className="grid grid-cols-2 gap-2">
              {/* Left Column: Time Slots */}
              <div className="overflow-y-scroll h-60 overflow-x-hidden custom-scrollbar border border-secondaryVariant2 rounded">
                {times.map((time) => (
                  <div
                    key={time.value}
                    className="flex items-center px-4 py-2 hover:bg-gray-100 cursor-pointer "
                    onClick={() => handleTimeChange(time.value)}
                  >
                    <label htmlFor={time.value} className="cursor-pointer ">
                      {time.label}
                    </label>
                  </div>
                ))}
              </div>

              {/* Right Column: AM/PM Options */}
              <div className="flex flex-col">
                {amPmOptions.map((option) => (
                  <button
                    key={option}
                    onClick={() => handlePeriodChange(option)}
                    className={`mb-2 px-4 py-2 rounded-lg border ${selectedPeriod === option ? "bg-gray-200" : "bg-white"
                      } border-gray-300 text-gray-600 cursor-pointer`}
                    type="button"
                  >
                    {option}
                  </button>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TimeDropdown;
