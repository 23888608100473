import React from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";

interface Props {
  id: string;
  place?: "top" | "bottom" | "left" | "right";
  className?: string;
  content: string;
  isWhite?: boolean;
}
export const TooltipComponent = ({
  id,
  place = "top",
  className = " max-w-[241px]",
  content,
  isWhite,
}: Props) => {
  return (
    <>
      <ReactTooltip
        id={id}
        place={place}
        content={content}
        className={`z-50 ${className} no-arrow-tooltip z-30 ${
          isWhite
            ? "bg-white text-[#000_!important] shadow-xl"
            : "bg-primary text-white"
        }`}
        opacity={100}
        html={content}
        // style={{
        //   marginLeft: "1rem", // Adjust margin for right alignment
        //   transform: "translateX(50%)", // Shift tooltip to the right
        // }}
      />
    </>
  );
};
