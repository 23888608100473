import { useQuery } from "@tanstack/react-query";
import { ChecklistQueryKeyString } from "../enums/checklistQueryKey.enum";
import { checklistSvc } from "../services/checklist.service";

export const useChecklist = () => {
  return useQuery({
    queryKey: [ChecklistQueryKeyString.Checklist],
    queryFn: () => checklistSvc.fetchCheckLists(),
  });
};
