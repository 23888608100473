import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ChecklistQueryKeyString } from "../enums/checklistQueryKey.enum";
import { checklistSvc } from "../services/checklist.service";
import { showErrorToast } from "../../shared/utils/toast";

export const useCompleteChecklist = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      checklistId,
      initials,
    }: {
      checklistId: string;
      initials: string;
    }) => checklistSvc.completeChecklistItem(checklistId, initials),
    onError: (err) => {
      console.log("error:", err);
      showErrorToast(err.message);
    },
    onSuccess: (e:any) => {
      if (e?.ok) {
        queryClient.invalidateQueries({
          queryKey: [ChecklistQueryKeyString.Checklist],
        });
      }
      else{
        showErrorToast(e?.error?.message)
      }
    },
  });
};
