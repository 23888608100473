export const apiBaseUrlAuth = process.env.REACT_APP_API_BASE_URL_AUTH as string;
export const apiBaseUrlProffer = process.env.REACT_APP_API_BASE_URL_PROFFER as string;
export const apiBaseUrlForay = process.env.REACT_APP_API_BASE_URL_FORAY as string;
export const apiBaseUrlUsers = process.env.REACT_APP_API_BASE_URL_USERS as string;
export const apiBaseUrlMedEvals = process.env.REACT_APP_API_BASE_URL_MEDEVALS as string;
export const apiBaseUrlAdventures = process.env.REACT_APP_API_BASE_URL_ADVENTURES as string;
export const clientID = process.env.REACT_APP_CLIENT_ID as string;
export const apiBaseUrlPayment = process.env.REACT_APP_API_BASE_URL_PAYMENT as string;
export const apiBaseUrlCCF = process.env.REACT_APP_API_BASE_URL_CCF as string;
export const adventuresUrl = process.env.REACT_APP_ADVENTURES_URL as string;

export const redirectURI = process.env.REACT_APP_REDIRECT_URI as string;
export const identityServerURI = process.env.REACT_APP_IDENTITY_SERVER_URI as string;
export const centerProfileUpdateURI = process.env.REACT_APP_IDENTITY_SERVER_CENTER_PROFILE_UPDATE_URI as string;
export const userProfileUpdateURI = process.env.REACT_APP_IDENTITY_SERVER_USER_PROFILE_UPDATE_URI as string;
export const identityServerUserRegURI = process.env.REACT_APP_IDENTITY_SERVER_REG_URI as string;

// export const redirectURI = process.env.REACT_APP_REDIRECT_URI_LOCAL as string;
// export const identityServerURI = process.env.REACT_APP_IDENTITY_SERVER_URI_LOCAL as string;
// export const centerProfileUpdateURI = process.env.REACT_APP_IDENTITY_SERVER_CENTER_PROFILE_UPDATE_URI_LOCAL as string;
// export const userProfileUpdateURI = process.env.REACT_APP_IDENTITY_SERVER_USER_PROFILE_UPDATE_URI_LOCAL as string;
// export const identityServerUserRegURI = process.env.REACT_APP_IDENTITY_SERVER_REG_URI_LOCAL as string;

export const googleAPIKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY_CCF as string;
export const stripePublishKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string;
export const stripePrcingTableId = process.env.REACT_APP_STRIPE_PRICING_TABLE_ID as string;
export const stripePremiumYearlyPriceId=process.env.REACT_APP_PREMIUM_PRICE_ID_YEARLY as string
export const stripePremiumMonthlyPriceId=process.env.REACT_APP_PREMIUM_PRICE_ID_MONTHLY as string

// to hide modules
export const hideAdventures = JSON.parse(process.env.REACT_APP_HIDE_ADVENTURES as string)
export const hideMedEval = JSON.parse(process.env.REACT_APP_HIDE_MED_EVAL as string)
export const hideProffer = JSON.parse(process.env.REACT_APP_HIDE_PROFFER as string)
export const hideForay = JSON.parse(process.env.REACT_APP_HIDE_FORAY as string)
