import React from "react";
import DashboardWrapper from "../../../shared/components/layout/DashboardWrapper";
import { forayRoutes } from "../../routes/routes";
import Breadcrumb from "../../../hr-setup_modules/components/Breadcrumb";
import ForayTabs from "../../components/forayTabs/ForayTabs";
import ClassroomDetailedList from "../../components/classroomDetail/ClassroomDetailedList";
import AddNew from "../../components/addNew/AddNew";

const ClassroomDetailed = () => {
  const breadcrumbItems = [
    { label: "Foray", link: forayRoutes.foray_screen },
    { label: "Classroom-Details", isCurrent: true },
  ];
  return (
    <>
      <DashboardWrapper>
        <div className="px-6 pt-5 bg-secondaryNeutral overflow-y-scroll h-[calc(100vh-85px)] custom-scrollbar">
          <Breadcrumb items={breadcrumbItems} />
          <ForayTabs />
          <ClassroomDetailedList />
          <AddNew
            buttonText="Go to Student Setup"
            route={forayRoutes.student_setup + "?pr=" + forayRoutes.classroom_detailed}
          />
        </div>
      </DashboardWrapper>
    </>
  );
};

export default ClassroomDetailed;
