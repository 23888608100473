import * as React from "react";
import { FiInfo } from "react-icons/fi";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const CompleteEmployeeSetup: React.FC<ModalProps> = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-[9999]">
      <div className="bg-white py-9 px-11 rounded-3xl shadow-lg w-[633px] space-y-9 flex items-center justify-center flex-col text-center">
        <div>
          <FiInfo className="w-[88px] h-[88px] text-primary" />
        </div>
        <h2 className="text-2xl text-secondary font-semibold">
          You have successfully completed the Employee setup
        </h2>
        <div className="flex items-center gap-4">
          <button className="btnPrimary w-[165px]">Go to List</button>
          <button
            className="btnSimple w-[145px] whitespace-nowrap"
            onClick={onClose}
          >
            Back to main
          </button>
          <button className="text-lg text-statusColor font-medium underline w-full whitespace-nowrap">
            Add New Employee
          </button>
        </div>
      </div>
    </div>
  );
};

export default CompleteEmployeeSetup;
