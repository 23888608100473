import React from 'react';
import DashboardWrapper from '../../../shared/components/layout/DashboardWrapper';
import Breadcrumb from '../../components/Breadcrumb';
import { forayRoutes } from '../../../foray_module/routes/routes';
import CustomTabs from '../../../foray_module/components/tabsLinks/CustomTabs';
import NavItemTabs from '../../../foray_module/components/tabsLinks/NavItemTabs';
import { useLocation } from 'react-router-dom';
import HandBookEmployee from './components/HandBookEmployee';

const HandBook = () => {
  const breadcrumbItems = [
    { label: 'Foray', link: forayRoutes.foray_screen },
    { label: 'Handbook', isCurrent: true },
  ];

  const { pathname } = useLocation();

  const navItems = [
    {
      label: 'Center',
      to: forayRoutes.center_view,
      isActive: pathname === forayRoutes.center_view,
    },
    {
      label: 'Classroom-Essentials',
      to: forayRoutes.essential_classroom_list,
      isActive: pathname === forayRoutes.essential_classroom_list,
    },
    {
      label: 'Classroom-Details',
      to: forayRoutes.classroom_detailed,
      isActive: pathname === forayRoutes.classroom_detailed,
    },
    {
      label: 'Handbook',
      to: forayRoutes.handbook,
      isActive: pathname === forayRoutes.handbook,
    },
  ];

  // Define the tabs data with the required 'eventKey' property
  const tabsData = [
    {
      eventKey: 'parent',
      label: 'Parent',
      content: <div>
      </div>,
    },
    {
      eventKey: 'employee',
      label: 'Employee',
      content: <HandBookEmployee/>,
    }
  ];

  return (
    <DashboardWrapper>
      <div className="px-6 py-8 space-y-6">
        <Breadcrumb items={breadcrumbItems} />
        <NavItemTabs items={navItems} />

        <h2 className="text-xl text-secondary font-medium mb-6">Handbooks</h2>
        <div className="bg-white p-4 rounded-xl">
          <CustomTabs tabs={tabsData} defaultActiveKey="employee" />
        </div>
      </div>
    </DashboardWrapper>
  );
};

export default HandBook;
