import { SchoolAgeProgram, StudentStatuses, WeekDay } from "../enums/Foray.enums";
import { IOptionS } from "../types/ICenterSetup.interface";

export const daysOfWeek = [
    { label: "Monday", value: WeekDay.MONDAY },
    { label: "Tuesday", value: WeekDay.TUESDAY },
    { label: "Wednesday", value: WeekDay.WEDNESDAY },
    { label: "Thursday", value: WeekDay.THURSDAY },
    { label: "Friday", value: WeekDay.FRIDAY },
    { label: "Saturday", value: WeekDay.Saturday },
    { label: "Sunday", value: WeekDay.Sunday },
];

export let timeOptions: IOptionS[] = [];
for (let hour = 12; hour <= 23; hour++) {
    for (let minute = 0; minute < 60; minute += 15) {
        let displayHour = hour > 12 ? hour - 12 : hour;
        let hourString = displayHour.toString().padStart(2, '0');
        let minuteString = minute === 0 ? "00" : minute.toString().padStart(2, '0');
        let timeString = `${hourString}:${minuteString}`;
        timeOptions.push({ label: timeString, value: timeString });
    }
}

export const SATypeOptions = [
    { label: "Before School", value: SchoolAgeProgram.BEFORE_SCHOOL },
    { label: "After School", value: SchoolAgeProgram.AFTER_SCHOOL },
    { label: "Before and After school", value: SchoolAgeProgram.BEFORE_AND_AFTER_SCHOOL },
    { label: "Other", value: SchoolAgeProgram.OTHER },
    { label: "N/A", value: SchoolAgeProgram.NA },
];

export const StudentStatusOptions = [
    { label: "Regd GS", value: StudentStatuses.RegdGS },
    { label: "Regd WL", value: StudentStatuses.RegdWL },
    { label: "Wait List", value: StudentStatuses.WaitList },
    { label: "Withdrawn", value: StudentStatuses.Withdrawn },
    { label: "Tour", value: StudentStatuses.Tour },
    { label: "CA Approval for Enrollment", value: StudentStatuses.CA_APPROVAL_FOR_ENROLLMENT },
    { label: "Drop-in", value: StudentStatuses.Drop_In },
];