import * as React from "react";
import { LuFileCheck } from "react-icons/lu";
import { IoCloseOutline } from "react-icons/io5";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const BonusPlanModal: React.FC<ModalProps> = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-[9999]">
      <div className="bg-white p-9 rounded-3xl shadow-lg w-[633px] relative">
        <button className="absolute right-4 top-5" onClick={onClose}>
          <IoCloseOutline className="w-6 h-6" />
        </button>
        <div className="flex items-center justify-center gap-9 flex-col">
          <div>
            <LuFileCheck className="w-[72px] h-[72px] text-primary" />
          </div>
          <h2 className="text-2xl text-secondary font-semibold">
            Nice work! You’ve created the bonus plan.{" "}
          </h2>
          <div className="flex items-center justify-center gap-4">
            <button className="btnPrimary max-w-[164px] whitespace-nowrap">
              Create New Plan
            </button>
            <button className="btnSimple max-w-[100px]" onClick={onClose}>
              Cancel{" "}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BonusPlanModal;
