import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  showErrorToast,
  showSuccessToast,
} from "../../../modules/shared/utils/toast";
import { stripeSvc } from "../../../modules/shared/services/stripe.service";
import { sharedRoutes } from "../../../modules/shared/routes/routes";

interface LocationState {
  subscriptionId: string;
  newPriceId: string;
}

const UpgradeConfirmation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = React.useState(false);
  const [amount, setAmount] = React.useState<number>(0);

  // Get state from location
  const state = location.state as LocationState;
  const { subscriptionId, newPriceId } = state || {};

  React.useEffect(() => {
    const calculateProration = async () => {
      try {
        const response = await stripeSvc.calculateUpgrade(
          subscriptionId,
          newPriceId
        );
        setAmount(response.data.amount < 0 ? 0 : response.data.amount);
      } catch (error) {
        console.error("Error calculating proration:", error);
        showErrorToast("Failed to calculate upgrade amount");
        navigate(sharedRoutes.pricing);
      }
    };

    if (subscriptionId && newPriceId) {
      calculateProration();
    } else {
      // If no state is present, redirect back to subscription page
      navigate(sharedRoutes.pricing);
    }
  }, [subscriptionId, newPriceId, navigate]);

  const handleConfirm = async () => {
    setLoading(true);
    try {
      const resp = await stripeSvc.updateSubscription(
        subscriptionId,
        newPriceId
      );

      if (resp.ok) {
        showSuccessToast("Subscription updated successfully");
        navigate(sharedRoutes.pricing);
      } else {
        throw new Error("Update failed");
      }
    } catch (error) {
      console.error("Error updating subscription:", error);
      showErrorToast("Failed to update subscription");
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    navigate("/subscription");
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen p-4">
      <div className="max-w-md w-full space-y-8 bg-white p-6 rounded-lg shadow-md">
        <div className="text-center">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">
            Confirm Subscription Change
          </h2>

          {amount > 0 ? (
            <p className="text-gray-600 mb-6">
              You will be charged ${amount.toFixed(2)} for this upgrade
            </p>
          ) : (
            <p className="text-gray-600 mb-6">
              Your subscription will be updated with no additional charge
            </p>
          )}

          <div className="space-y-4">
            <button
              onClick={handleConfirm}
              disabled={loading}
              className={`w-full py-2 px-4 rounded-md text-white font-medium
                ${
                  loading
                    ? "bg-blue-400 cursor-not-allowed"
                    : "bg-blue-600 hover:bg-blue-700"
                } transition-colors duration-200`}
            >
              {loading ? (
                <span className="flex items-center justify-center">
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Processing...
                </span>
              ) : (
                "Confirm"
              )}
            </button>

            <button
              onClick={handleCancel}
              disabled={loading}
              className={`w-full py-2 px-4 border border-gray-300 rounded-md text-gray-700 font-medium
                ${
                  loading
                    ? "bg-gray-100 cursor-not-allowed"
                    : "bg-white hover:bg-gray-50"
                } transition-colors duration-200`}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpgradeConfirmation;
