import React, { FC } from "react";
import { Link } from "react-router-dom";
import { IoCheckmarkOutline } from "react-icons/io5";

interface IProps {
  subscriptionPeriod: string
  handleSubscriptionPeriod: (period: string) => void;
}

const BillingPlans: FC<IProps> = ({ subscriptionPeriod, handleSubscriptionPeriod }) => {

  return (
    <>
      <div className="grid grid-cols-3 gap-3 items-stretch">
        <div className="pb-6">
          <h3 className="text-lg text-secondary font-semibold mb-2">
            Billing/Pay
          </h3>
          <div className="flex items-start gap-4 mb-2">
            <span className="text-base text-secondary">Monthly</span>

            <div className="relative inline-block w-11 h-6">
              <input
                type="checkbox"
                id="strategicView"
                className="hidden peer"
                checked={subscriptionPeriod === "monthly" ? false : true}
                onChange={(e) => {
                  const temp = e?.target?.checked ? "yearly" : "monthly";
                  handleSubscriptionPeriod(temp);
                }}
              />
              <label
                htmlFor="strategicView"
                className="bg-[#DADADA] peer-checked:bg-[#18C55F] rounded-full cursor-pointer w-full h-full block transition-colors duration-300 ease-in-out"
              ></label>
              <div className="dot pointer-events-none absolute left-0 top-0 bg-white w-6 h-6 rounded-full shadow-md transition-transform duration-300 ease-in-out peer-checked:translate-x-full"></div>
            </div>

            <span className="text-base text-secondary flex flex-col items-start">
              Yearly{" "}
              <span className="text-base text-primary font-medium">
                (Save 15% annually)
              </span>
            </span>
          </div>
        </div>

        <div className="pb-6 flex flex-col justify-between">
          <div>
            <h3 className="text-lg text-secondary font-semibold mb-2">
              Plan 1 (Primary){" "}
            </h3>
            <h3 className="text-2xl text-primary font-semibold mb-2">
              $0 <span className="font-normal">(Complimentary)</span>
            </h3>
          </div>
          <button className="btnPrimary max-w-[100px] mt-6">Subscribed</button>
        </div>

        {/* Third Column */}
        <div className="pb-6">
          <h3 className="text-lg text-secondary font-semibold mb-2">
            Plan 2 (Foray Basic){" "}
          </h3>
          <h3 className="text-2xl text-primary font-semibold mb-2">
            $60.00/<span className="font-normal">month</span>
          </h3>
          <p className="text-base text-secondary">
            After 90 days free trial, this subscription will continue
            automatically.
          </p>
          <Link to={"/"} className="text-base text-statusColor underline">
            Learn more about Foray
          </Link>
          <button className="btnPrimary max-w-[100px] mt-6">Subscribe</button>
        </div>
      </div>
      <div className="bg-white">
        <h3 className="text-lg text-secondary font-semibold py-3 px-4">
          What’s Included
        </h3>
        <div className="py-3 px-4 w-full flex items-center border-t border-secondaryNeutral">
          <div className="w-1/3 text-secondaryVariant text-lg">Adventures</div>
          <div className="w-1/3 text-successAlert ps-4">
            <IoCheckmarkOutline className="w-6 h-6" />
          </div>
          <div className="w-1/3 text-successAlert ps-8">
            <IoCheckmarkOutline className="w-6 h-6" />
          </div>
        </div>
        <div className="py-3 px-4 w-full flex items-center border-t border-secondaryNeutral">
          <div className="w-1/3 text-secondaryVariant text-lg">
            Medical Evals (Access)
          </div>
          <div className="w-1/3 text-successAlert ps-4">
            <IoCheckmarkOutline className="w-6 h-6" />
          </div>
          <div className="w-1/3 text-successAlert ps-8">
            <IoCheckmarkOutline className="w-6 h-6" />
          </div>
        </div>
        <div className="py-3 px-4 w-full flex items-center border-t border-secondaryNeutral">
          <div className="w-1/3 text-secondaryVariant text-lg">
            Tracking Enrollment
          </div>
          <div className="w-1/3 text-successAlert ps-4"></div>
          <div className="w-1/3 text-successAlert ps-8">
            <IoCheckmarkOutline className="w-6 h-6" />
          </div>
        </div>
        <div className="py-3 px-4 w-full flex items-center border-t border-secondaryNeutral">
          <div className="w-1/3 text-secondaryVariant text-lg">
            Task Generation
          </div>
          <div className="w-1/3 text-successAlert ps-4"></div>
          <div className="w-1/3 text-successAlert ps-8">
            <IoCheckmarkOutline className="w-6 h-6" />
          </div>
        </div>
        <div className="py-3 px-4 w-full flex items-center border-t border-secondaryNeutral">
          <div className="w-1/3 text-secondaryVariant text-lg">Checklist</div>
          <div className="w-1/3 text-successAlert ps-4">
            {/* <IoCheckmarkOutline className="w-6 h-6" /> */}
          </div>
          <div className="w-1/3 text-successAlert ps-8">
            <IoCheckmarkOutline className="w-6 h-6" />
          </div>
        </div>
        <div className="py-3 px-4 w-full flex items-center border-t border-secondaryNeutral">
          <div className="w-1/3 text-secondaryVariant text-lg">
            Center Management
          </div>
          <div className="w-1/3 text-successAlert ps-4"></div>
          <div className="w-1/3 text-successAlert ps-8">
            <IoCheckmarkOutline className="w-6 h-6" />
          </div>
        </div>
      </div>
    </>
  );
};

export default BillingPlans;
