/* eslint-disable react-hooks/rules-of-hooks */
import * as React from "react";
import ReactSelect from "../../../foray_module/components/reactSelect/ReactSelect";
import InputField from "../../../foray_module/components/inputsFields/InputField";
import BonusPlanModal from "./BonusPlanModal";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const CreateBonusPlanModal: React.FC<ModalProps> = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  const [isModalOpen, setModalOpen] = React.useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-[9999]">
      <div className="bg-white p-9 rounded-3xl shadow-lg w-[633px] space-y-9">
        <h2 className="text-xl text-secondary font-semibold">
          Create Bonus Plan{" "}
        </h2>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <ReactSelect label={"Plan Type"} placeholder={""} required />
          </div>
          <div>
            <InputField
              label="Plan Name (optional)"
              type={"text"}
              placeholder={""}
            />
          </div>
          <div>
            <ReactSelect label={"Frequency"} placeholder={""} required />
          </div>
          <div>
            <ReactSelect label={"How Much?"} placeholder={""} required />
          </div>
        </div>
        <div className="flex items-center gap-4">
          <button className="btnPrimary max-w-[132px]" onClick={openModal}>
            Save Plan
          </button>
          <button className="btnSimple max-w-[100px]" onClick={onClose}>
            Cancel{" "}
          </button>
          <BonusPlanModal isOpen={isModalOpen} onClose={closeModal} />
        </div>
      </div>
    </div>
  );
};

export default CreateBonusPlanModal;
