import React from 'react';
import { ArrowUp, ArrowDown } from 'iconsax-react';

interface CardStatsProps {
  title: string;
  value: number;
  trend: number;
  icon: React.ReactNode;
  iconBgColor: string;
}

const CardStatsSuperAdmin: React.FC<CardStatsProps> = ({ title, value, trend, icon, iconBgColor }) => {
  return (
    <div className="bg-white rounded-xl p-4">
      <div className="flex items-center gap-3 mb-1">
        <div className="flex-1 flex items-center gap-2">
          <i
            className="inline-flex items-center justify-center w-9 h-9 rounded-lg"
            style={{ backgroundColor: iconBgColor }}
          >
            {icon}
          </i>
          <span className="flex-1">{title}</span>
        </div>
        <div className="text-2xl font-semibold text-secondary">{value}</div>
      </div>
      <p className="text-sm text-secondaryVariant">
        Previous complete month{' '}
        <span
          className={`inline-flex items-center gap-1 py-1 px-3 rounded-full text-xs ${
            trend >= 0 ? 'bg-green-100 text-green-500' : 'bg-red-100 text-red-500'
          }`}
        >
          {trend >= 0 ? <ArrowUp size="12" /> : <ArrowDown size="12" />}
          {Math.abs(trend)}%
        </span>
      </p>
    </div>
  );
};

export default CardStatsSuperAdmin;
