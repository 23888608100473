import { useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { Nav } from "react-bootstrap";
import { GoPlus } from "react-icons/go";
import Breadcrumb from "../../../hr-setup_modules/components/Breadcrumb";
import { ChecklistType } from "../../../hr-setup_modules/enums/checklist";
import DashboardWrapper from "../../../shared/components/layout/DashboardWrapper";
import AddDailyTaskModal from "../../components/ChecklistComponents/AddDailyTaskModal";
import AddMonthlyTaskModal from "../../components/ChecklistComponents/AddMonthlyTaskModal";
import ChecklistCard from "../../components/ChecklistComponents/ChecklistCard";
import DailyChecklistTable from "../../components/tables/DailyChecklistTable";
import MonthlyChecklistTable from "../../components/tables/MonthlyChecklistTable";
import { Months, WeekDay } from "../../enums/Foray.enums";
import { ChecklistQueryKeyString } from "../../enums/checklistQueryKey.enum";
import { useAddDailyChecklist } from "../../hooks/useAddDailyChecklist";
import { useAddMonthlyChecklist } from "../../hooks/useAddMonthlyChecklist";
import { useCompleteChecklist } from "../../hooks/useCompleteDailyChecklist";
import { useDailyChecklist } from "../../hooks/useDailyChecklist";
import { useMonthlyChecklist } from "../../hooks/useMonthlyChecklist";
import { useRemoveChecklistItem } from "../../hooks/useRemoveChecklistItem";
import { useUpdateDailyChecklist } from "../../hooks/useUpdateDailyChecklist";
import { useUpdateMonthlyChecklist } from "../../hooks/useUpdateMonthlyChecklist";
import { forayRoutes } from "../../routes/routes";
import {
  IDailyReqBody,
  IMonthlyChecklist,
  IMonthlyReqBody,
} from "../../types/IChecklist.interface";

const CheckList = () => {
  const breadcrumbItems = [
    { label: "Foray", link: forayRoutes.foray_screen },
    { label: "CheckList", isCurrent: true },
  ];
  const [isModalOpen, setModalOpen] = React.useState(false);
  const [selectedChecklist, setSelectedChecklist] = useState(
    ChecklistType.Daily
  );
  const [isDailyModalOpen, setIsDailyModalOpen] = useState(false);
  const [isDailyEditModalOpen, setIsDailyEditModalOpen] = useState(false);
  const [checklist, setChecklist] = useState<IDailyReqBody>({
    type: ChecklistType.Daily,
    task: "",
    time: "",
    dayOfWeek: null,
  });
  const [checklistToEdit, setChecklistToEdit] = useState<any>(null);
  const [newMonthlyChecklist, setNewMonthlyChecklist] =
    useState<IMonthlyReqBody>({
      type: ChecklistType.Monthly,
      task: "",
      month: null,
    });
  const [rowToEdit, setRowToEdit] = useState("");
  const [initials, setInitials] = useState("");
  const [activeDay, setActiveDay] = useState<WeekDay>(WeekDay.MONDAY);
  const [activeMonth, setActiveMonth] = useState<Months>(Months.January);
  const [monthlyItemToEdit, setMonthlyItemToEdit] = useState("");
  const [isMonthlyEditMode, setIsMonthlyEditMode] = useState<boolean>(false);

  //useQuey
  const queryClient = useQueryClient();
  const { mutate } = useAddDailyChecklist();
  const { mutate: completedMutate, isError, error } = useCompleteChecklist();
  const { data: dailyChecklist } = useDailyChecklist(activeDay);
  const { data: monthlyChecklist } = useMonthlyChecklist(activeMonth);
  const { mutate: addMonthlyTask } = useAddMonthlyChecklist();
  const { mutate: updateDaily } = useUpdateDailyChecklist();
  const { mutate: deleteItem } = useRemoveChecklistItem();
  const { mutate: updateMonthly } = useUpdateMonthlyChecklist();

  const closeDailyModal = () => {
    setChecklist({
      type: ChecklistType.Daily,
      task: "",
      time: "",
      dayOfWeek: null,
    });
    setIsDailyModalOpen(false);
  };
  const openModal = (isDaily: boolean) => {
    if (isDaily) {
      setModalOpen(false);
      setIsDailyModalOpen(true);
    } else {
      setIsDailyModalOpen(false);
      setModalOpen(true);
    }
  };

  const closeModal = () => {
    setModalOpen(false);
    setIsMonthlyEditMode(false);
    setNewMonthlyChecklist({
      type: ChecklistType.Monthly,
      task: "",
      month: null,
    });
  };

  const handleChecklistSelect = (selectedChecklist: ChecklistType) => {
    setSelectedChecklist(selectedChecklist);
  };

  const handleDailyChecklistEdit = (id: string) => {
    setRowToEdit(id);
  };

  const handleMonthlyChecklistEdit = (id: string) => {
    setMonthlyItemToEdit(id);
  };

  const onDailyChecklistChange = (
    fieldName: keyof IDailyReqBody,
    value: any
  ) => {
    // if (fieldName === "dateTime") {
    //   // Parse the time value
    //   const [hours, minutes] = value.split(":");

    //   // Create a new Date object with the current date and specified time
    //   const formattedDateTime = moment()
    //     .set({
    //       hour: Number(hours),
    //       minute: Number(minutes),
    //       second: 0,
    //       millisecond: 0,
    //     })
    //     .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

    //   setChecklist((prev: any) => ({
    //     ...prev,
    //     [fieldName]: formattedDateTime,
    //   }));
    // } else {

    // }

    setChecklist((prev: any) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const handleEditMonthlyItem = (item: any) => {
    setNewMonthlyChecklist(item);
    setIsMonthlyEditMode(true);
    setModalOpen(true);
  };

  const handleEditChecklist = (item: any) => {
    console.log("item", item);
    setChecklistToEdit(item);
    setIsDailyEditModalOpen(true);
  };

  const onDailyChecklistEditChange = (
    fieldName: keyof IDailyReqBody,
    value: any
  ) => {
    setChecklistToEdit((prev: any) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const handleChecklistDelete = () => {};

  const onMonthlyChecklistChange = (
    fieldName: keyof IMonthlyChecklist,
    value: any
  ) => {
    setNewMonthlyChecklist((prev: any) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const handelInitialsChange = (value: string) => {
    setInitials(value);
  };

  const handleSave = () => {
    if (rowToEdit !== "") {
      completedMutate({ checklistId: rowToEdit, initials: initials });
    }
    setRowToEdit("");
  };

  const handleDaySelect = (selectedDay: string | null) => {
    if (selectedDay) {
      setActiveDay(selectedDay as WeekDay);
    }
  };

  const handleMonthSelect = (month: any) => {
    console.log(month);
    if (month) {
      setActiveMonth(month);
    }
  };

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: [ChecklistQueryKeyString.DailyChecklist],
    });
  }, [activeDay]);

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: [ChecklistQueryKeyString.MonthlyChecklist],
    });
  }, [activeMonth]);

  return (
    <>
      <DashboardWrapper>
        <div className="px-6 pt-6 ">
          <Breadcrumb items={breadcrumbItems} />
          <ChecklistCard
            selectedChecklist={selectedChecklist}
            handleChecklistSelect={handleChecklistSelect}
          />
          <div>
            {selectedChecklist === ChecklistType.Daily && (
              <Nav
                activeKey={activeDay}
                variant="pills"
                className="flex items-center border-b border-secondaryVariant2 mb-4 w-full gap-8"
                onSelect={handleDaySelect}
              >
                {Object.values(WeekDay).map((day) => (
                  <Nav.Item key={day}>
                    <Nav.Link
                      eventKey={day}
                      className="inline-flex items-center p-[18px] pb-3 [&.active]:text-primary font-medium border-b-[2px] border-transparent [&.active]:border-primary"
                    >
                      {day}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            )}

            {selectedChecklist === ChecklistType.Monthly && (
              <Nav
                activeKey={activeMonth}
                variant="pills"
                className="flex items-center border-b border-secondaryVariant2 mb-4 w-full gap-1"
                onSelect={handleMonthSelect}
              >
                {Object.values(Months).map((month) => (
                  <Nav.Item key={month}>
                    <Nav.Link
                      eventKey={month}
                      className="inline-flex items-center p-[18px] pb-3 [&.active]:text-primary font-medium border-b-[2px] border-transparent [&.active]:border-primary"
                    >
                      {month}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            )}
            {/* <Nav
              activeKey={event}
              variant="pills"
              className="flex items-center border-b border-secondaryVariant2 mb-4 w-full gap-8"
            >
              <Nav.Item>
                <Nav.Link
                  eventKey="Monday"
                  className="inline-flex items-center ps-0 p-[18px] pb-3 [&.active]:text-primary font-medium border-b-[2px] border-transparent [&.active]:border-primary"
                >
                  Monday
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="Tuesday"
                  className="inline-flex items-center p-[18px] pb-3 [&.active]:text-primary font-medium border-b-[2px] border-transparent [&.active]:border-primary"
                >
                  Tuesday
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="Wednesday"
                  className="inline-flex items-center p-[18px] pb-3 [&.active]:text-primary font-medium border-b-[2px] border-transparent [&.active]:border-primary"
                >
                  Wednesday
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="Thursday"
                  className="inline-flex items-center p-[18px] pb-3 [&.active]:text-primary font-medium border-b-[2px] border-transparent [&.active]:border-primary"
                >
                  Thursday
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="Friday"
                  className="inline-flex items-center p-[18px] pb-3 [&.active]:text-primary font-medium border-b-[2px] border-transparent [&.active]:border-primary"
                >
                  Friday
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="Saturday"
                  className="inline-flex items-center p-[18px] pb-3 [&.active]:text-primary font-medium border-b-[2px] border-transparent [&.active]:border-primary"
                >
                  Saturday
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="Sunday"
                  className="inline-flex items-center p-[18px] pb-3 [&.active]:text-primary font-medium border-b-[2px] border-transparent [&.active]:border-primary"
                >
                  Sunday
                </Nav.Link>
              </Nav.Item>
            </Nav> */}
          </div>

          {selectedChecklist === ChecklistType.Daily && (
            <DailyChecklistTable
              handleEdit={handleDailyChecklistEdit}
              rowToEdit={rowToEdit}
              initials={initials}
              handleChange={handelInitialsChange}
              // dailyChecklist={dailyChecklist?.data?.data.Afternoon}
              checklist={dailyChecklist?.data?.data}
              handleEditChecklist={handleEditChecklist}
              deleteItem={deleteItem}
            />
          )}
          {selectedChecklist === ChecklistType.Monthly &&
            monthlyChecklist?.data && (
              <MonthlyChecklistTable
                monthlyChecklist={monthlyChecklist.data}
                handleEdit={handleMonthlyChecklistEdit}
                rowToEdit={monthlyItemToEdit}
                initials={initials}
                handleChange={handelInitialsChange}
                deleteItem={deleteItem}
                handleEditMode={handleEditMonthlyItem}
              />
            )}

          <div className="flex justify-end my-5">
            <div className="flex items-center gap-7">
              {rowToEdit && <button onClick={handleSave}>Save</button>}
              <button
                className="btnPrimary max-w-[181px]"
                onClick={() =>
                  openModal(selectedChecklist === ChecklistType.Daily)
                }
              >
                Add New Task <GoPlus className="w-5 h-5" />
              </button>
              <AddDailyTaskModal
                isOpen={isDailyModalOpen}
                onClose={closeDailyModal}
                checklist={checklist}
                onChecklistChange={onDailyChecklistChange}
                mutate={mutate}
              />
              <AddMonthlyTaskModal
                checklist={newMonthlyChecklist}
                onMonthlyChecklistChange={onMonthlyChecklistChange}
                mutate={isMonthlyEditMode ? updateMonthly : addMonthlyTask}
                isOpen={isModalOpen}
                onClose={closeModal}
                isEditMode={isMonthlyEditMode}
              />

              {/* Edit Modal */}
              <AddDailyTaskModal
                isOpen={isDailyEditModalOpen}
                onClose={() => setIsDailyEditModalOpen(false)}
                checklist={checklistToEdit}
                onChecklistChange={onDailyChecklistEditChange}
                mutate={updateDaily}
                isEdit={true}
              />
            </div>
          </div>
        </div>
      </DashboardWrapper>
    </>
  );
};

export default CheckList;
