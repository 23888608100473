import React from 'react'
import { TbFileDescription } from 'react-icons/tb'

const UploadInput = () => {
  return (
    <div>
        <input type="file" id='file' name='file' hidden  />
        <label htmlFor="file" className='flex items-center gap-4 bg-white rounded-lg border border-dotted border-primary p-5'>
            <span className='inline-flex items-center justify-center w-10 h-10 rounded-lg bg-primary'>
                <TbFileDescription/>
            </span>
            <div className='flex-1'>
                <p className='text-secondary'>Drag and Drop your <strong>Employee Handbook</strong> here or click <strong>“Upload”</strong> to select a file</p>
                <p className='text-xs text-[#686C82] '>Supported formats : docx, pdf</p>
            </div>
            <button className='text-secondary h-10 px-3 rounded-lg bg-[#F2F2F2] border border-secondary'>Upload</button>
        </label>
    </div>
  )
}

export default UploadInput