import { WeekDay } from "../../enums/Foray.enums";

export const getTodayDayName = (): WeekDay => {
  const days = [
    WeekDay.Sunday,
    WeekDay.MONDAY,
    WeekDay.TUESDAY,
    WeekDay.WEDNESDAY,
    WeekDay.THURSDAY,
    WeekDay.FRIDAY,
    WeekDay.Saturday,
  ];
  const today = new Date().getDay(); // 0 = Sunday, 1 = Monday, etc.
  return days[today];
};
