import { useNavigate } from "react-router-dom";
import PricingPlans from "../../components/pricingPlans/PricingPlans";
import { sharedRoutes } from "../../routes/routes";
import MainWrapper from "../../components/layout/MainWrapper";

const PricingPage = () => {
  const navigate = useNavigate();

  const handleSelectPlan = (priceId: string) => {
    // Navigate to checkout with the selected price ID
    navigate(sharedRoutes.checkout, {
      state: {
        priceId,
      },
    });
  };

  return (
    
    <MainWrapper>
      <section className="py-[100px]">
      <div className="container">
        <h2 className="text-2xl md:text-xll xl:text-4xl text-secondary font-semibold mb-3 text-center">Choose Your Plan</h2>
        <p className="text-lg text-secondaryVariant mb-4 md:mb-9 text-center">Select the perfect plan for your needs</p>
        
      <PricingPlans onSelectPlan={handleSelectPlan} />
      
      </div>
      </section>
    </MainWrapper>
  );
};

export default PricingPage;
