import { useMutation, useQueryClient } from "@tanstack/react-query";
import { showSuccessToast } from "../../shared/utils/toast";
import { ChecklistQueryKeyString } from "../enums/checklistQueryKey.enum";
import { checklistSvc } from "../services/checklist.service";

export const useRemoveChecklistItem = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ itemId }: { itemId: string }) =>
      checklistSvc.deleteChecklistItem(itemId),
    onError: (err) => {
      console.error("error:", err);
    },
    onSuccess: (response) => {
      if (response?.ok) {
        showSuccessToast("Checklist item deleted successfully");
        // queryClient.invalidateQueries({
        //   queryKey: [ChecklistQueryKeyString.Checklist],
        // });
        queryClient.invalidateQueries({
          queryKey: [ChecklistQueryKeyString.DailyChecklist],
        });
        queryClient.invalidateQueries({
          queryKey: [ChecklistQueryKeyString.MonthlyChecklist],
        });
      }
    },
  });
};
