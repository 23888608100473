import { useMutation, useQueryClient } from "@tanstack/react-query";
import { showErrorToast, showSuccessToast } from "../../shared/utils/toast";
import { taskGenerationSvc } from "../services/taskgeneration.service";
import { TaskGenerationQueryKeyString } from "../enums/taskgenerationQueryKey.enum";

export const useToggleColumnVisibility = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({
            categoryId,
            sectionId,
            reqBody,
        }: {
            categoryId: string;
            sectionId: string;
            reqBody: { columnUpdates: [{ columnPath: string, visible: boolean }] };
        }) => taskGenerationSvc.toggleColVisibility(categoryId, sectionId, reqBody),
        onError: (err) => {
            console.error("error:", err);
        },
        onSuccess: (res) => {
            if (res?.ok) {
                showSuccessToast("Column toggled successfully")
                queryClient.invalidateQueries({
                    queryKey: [TaskGenerationQueryKeyString.Category],
                });
                // queryClient.invalidateQueries({
                //     queryKey: [TaskGenerationQueryKeyString.Tasks],
                // });
            } else {
                showErrorToast("Couldn't toggle column")
            }
        },
    });
};
