import React from "react";
import RequiredAsterisk from "../../../shared/components/ui/RequiredAsterisk";
import { MdInfoOutline } from "react-icons/md";
import { TooltipComponent } from "../../../shared/components/tooltip/Tooltip";

interface Props {
  label?: string;
  type: string;
  required?: boolean;
  name?: string;
  placeholder: string;
  value?: string | number;
  checked?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (name: string) => void;
  onBlur?: (name: string) => void;
  disabled?: boolean;
  min?: number;
  showInfoIcon?: boolean;
  tooltipId?: string;
  tooltipContent?: string;
  capitalizeTextClass?: boolean; // New prop to conditionally apply capitalization
}

const InputField: React.FC<Props> = ({
  label,
  type,
  required = false,
  name,
  placeholder,
  value,
  checked,
  onChange,
  onFocus,
  onBlur,
  disabled = false,
  min,
  showInfoIcon = false, // Default is false
  tooltipId,
  tooltipContent,
  capitalizeTextClass = false, // Default is false
}) => {
  return (
    <div>
      <label
        htmlFor={name}
        className="text-base text-secondaryVariant flex items-start gap-2"
      >
        <span>
          {label}
          {required && <RequiredAsterisk />}
        </span>
        {showInfoIcon && (
          <span data-tooltip-id={tooltipId}>
            <MdInfoOutline className="text-primary w-[18px] h-[18px]" />
          </span>
        )}
        <TooltipComponent
          id={tooltipId || ""}
          content={tooltipContent || ""}
          isWhite={true}
        />
      </label>
      <input
        min={type === "number" ? (min ? min : 0) : undefined}
        type={type}
        placeholder={placeholder}
        name={name}
        className={`border border-secondaryVariant2 rounded-lg py-3.5 ps-3 pe-2 w-full mt-1 bg-white ${
          disabled ? "opacity-50" : ""
        } ${capitalizeTextClass ? "capitalize" : ""}`}
        value={type !== "checkbox" ? value : undefined}
        checked={type === "checkbox" ? checked : undefined} // Handle checkbox
        onChange={onChange}
        onFocus={() => name && onFocus?.(name)}
        onBlur={() => name && onBlur?.(name)}
        disabled={disabled}
      />
    </div>
  );
};

export default InputField;
