import * as React from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { CgEye } from "react-icons/cg";
import { BiEdit } from "react-icons/bi";
import { RiDeleteBin3Line } from "react-icons/ri";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { StudentTable } from "../../types/IStudentSetup.interface";
import { Slot, StudentStatuses } from "../../enums/Foray.enums";
import { useLocation, useNavigate } from "react-router-dom";
import { forayRoutes } from "../../routes/routes";
import { studentSvc } from "../../services/student.service";
import { useMutation } from "@tanstack/react-query";
import { queryClient } from "../../../../config/useQuery.config";
import { PiUsers } from "react-icons/pi";
import useOutsideClick from "../../../shared/hooks/useOutsideClick";

const columnHelper = createColumnHelper<StudentTable>();

interface ActionDropdownProps {
  studentId: string;
  isActive: boolean;
  isOpen: boolean;
  toggleDropdown: (id: string) => void;
  fetchAgain: boolean;
  setFetchAgain: (fetch: boolean) => void;
}

const ActionDropdown: React.FC<ActionDropdownProps> = ({
  studentId,
  isActive,
  isOpen,
  toggleDropdown,
  fetchAgain,
  setFetchAgain,
}) => {
  const actionModalRef = React.useRef<HTMLDivElement>(null);
  useOutsideClick(actionModalRef, () => isOpen && toggleDropdown(studentId));
  const navigate = useNavigate();
  const location = useLocation();

  const isParentInfo = location.pathname === forayRoutes.parent_info;

  const handleView = () => {
    navigate(forayRoutes.view_student_profile + `?pr=${location.pathname}`, {
      state: { studentId, classroomId: location.state?.classroomId },
    });
  };

  const handleParentProfile = () => {
    navigate(forayRoutes.parent_info + `?pr=${location.pathname}`, {
      state: { studentId, classroomId: location.state?.classroomId },
    });
  };

  const { mutate: archiveStudentMutation } = useMutation({
    mutationFn: async () =>
      await studentSvc.archiveStudent(studentId, !isActive),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["students"] });
      toggleDropdown(studentId);
      setFetchAgain(!fetchAgain);
    },
  });

  const handleArchive = () => {
    archiveStudentMutation();
  };

  return (
    <div className="relative text-end">
      <button
        onClick={() => toggleDropdown(studentId)}
        className="focus:outline-none"
      >
        <BsThreeDotsVertical className="w-6 h-6 text-secondary" />
      </button>
      {isOpen && (
        <div
          className="absolute right-0 mt-2 w-56 bg-white shadow-lg rounded-md z-10 p-4"
          ref={actionModalRef}
        >
          <ul>
            <li
              className="px-1 py-4 hover:bg-gray-100 cursor-pointer rounded-md"
              onClick={handleView}
            >
              <button className="text-base text-secondary flex items-center gap-4">
                <CgEye className="w-6 h-6 text-secondaryVariant2" />
                View/Modify
              </button>
            </li>
            {!isParentInfo && (
              <li
                className="px-1 py-4 hover:bg-gray-100 cursor-pointer rounded-md"
                onClick={handleParentProfile}
              >
                <button className="text-base text-secondary flex items-center gap-4">
                  <PiUsers className="w-6 h-6 text-secondaryVariant2" />
                  Parent Details
                </button>
              </li>
            )}
            {/* <li
              className="px-1 py-4 hover:bg-gray-100 cursor-pointer rounded-md"
              onClick={handleModify}
            >
              <button className="text-base text-secondary flex items-center gap-4">
                <BiEdit className="w-6 h-6 text-secondaryVariant2" />
                Modify
              </button>
            </li> */}
            <li
              className="px-1 py-4 hover:bg-gray-100 cursor-pointer rounded-md"
              onClick={handleArchive}
            >
              <button className="text-base text-secondary flex items-center gap-4">
                <RiDeleteBin3Line className="w-6 h-6 text-secondaryVariant2" />
                {isActive ? "Archive" : "Un-archive"}
              </button>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};
const statusStyles = {
  Enrolled: { bgColor: "#25A2441A", textColor: "#25A244" },
  RegdGS: { bgColor: "#4AD66D1A", textColor: "#4AD66D" },
  RegdWL: { bgColor: "#AACC001A", textColor: "#BFD200" },
  WaitList: { bgColor: "#FBB02D1A", textColor: "#FBB02D" },
  Withdrawn: { bgColor: "#DB3B211A", textColor: "#DB3B21" },
  Tour: { bgColor: "#00B4D81A", textColor: "#00B4D8" },
  "CA Approval for Enrollment": { bgColor: "#0466C81A", textColor: "#0466C8" },
  "Drop-in": { bgColor: "#9EB3C21A", textColor: "#9EB3C2" },
};

const archiveStyles = { bgColor: "#DADADA", textColor: "#BEBFCD" };

const StudentsTable: React.FC<any> = ({
  students,
  fetchAgain,
  setFetchAgain,
}) => {
  const [openDropdownId, setOpenDropdownId] = React.useState<string | null>(
    null
  );

  const toggleDropdown = (id: string) => {
    setOpenDropdownId((prevId) => (prevId === id ? null : id));
  };

  const columns = [
    columnHelper.accessor("profilePictureUrl", {
      id: "profilePictureUrl",
      header: () => (
        <div className="flex items-center gap-3">
          <span>Photo</span>
        </div>
      ),
      cell: (info) => (
        <div className="flex items-center gap-3">
          <img
            src={info.getValue() || "https://a360csastorage.blob.core.windows.net/childcare/8669bd41-eb7e-48c2-b750-e7cfdff8cbde-fileName-dummyStudent-img.png"}
            alt="student"
            className="w-9 h-9 object-cover rounded-full"
          />
        </div>
      ),
    }),
    columnHelper.accessor("studentName", {
      id: "studentName",
      header: () => (
        <div className="flex items-center gap-3">
          <span>Student Name</span>
        </div>
      ),
      cell: (info) => (
        <div className="flex items-center gap-3">
          <span className="w-full">{info.getValue()}</span>
        </div>
      ),
    }),
    columnHelper.accessor("age", {
      id: "age",
      header: () => (
        <div className="text-start">
          <span>Age</span>
        </div>
      ),
      cell: (info) => <div className="text-start">{info.getValue()}</div>,
    }),
    columnHelper.accessor("classroomName", {
      id: "classroomName",
      header: () => (
        <div className="text-start">
          <span>Classroom Name</span>
        </div>
      ),
      cell: (info) => <div className="text-start">{info.getValue()}</div>,
    }),
    columnHelper.accessor("slot", {
      id: "slot",
      header: () => (
        <div className="text-start">
          <span>Full/Part Time</span>
        </div>
      ),
      cell: (info) => (
        <div className="text-start">
          {info.getValue() &&
            (info.getValue()[0] === Slot.FULL_TIME
              ? Slot.FULL_TIME
              : Slot.PART_TIME)}
        </div>
      ),
    }),
    columnHelper.accessor("joiningDate", {
      id: "joiningDate",
      header: () => (
        <div className="text-start">
          <span>Start Date</span>
        </div>
      ),
      cell: (info) => <div className="text-start">{info.getValue()}</div>,
    }),
    columnHelper.accessor("transitionDate", {
      id: "transitionDate",
      header: () => (
        <div className="text-start">
          <span>Next Transition</span>
        </div>
      ),
      cell: (info) => <div className="text-start">{info.getValue()}</div>,
    }),
    columnHelper.accessor("status", {
      id: "status",
      header: () => (
        <div className="text-center">
          <span>Status</span>
        </div>
      ),
      cell: (info) => {
        const status = info.getValue();
        const isActive = info.row.original.isActive; // Assuming `isActive` is part of the row data

        // Use archive styles if isActive is false, otherwise use status styles
        const { bgColor, textColor } = isActive
          ? statusStyles[status as keyof typeof statusStyles] || {
            bgColor: "transparent",
            textColor: "black",
          }
          : archiveStyles;

        return (
          <div className="flex justify-center items-center">
            <div
              className="rounded-2xl px-3 py-0.5 max-w-max"
              style={{ backgroundColor: bgColor, color: textColor }}
            >
              {isActive ? status : "Archive"}
            </div>
          </div>
        );
      },
    }),
    columnHelper.accessor("action", {
      id: "action",
      header: () => (
        <div className="text-end">
          <span>Action</span>
        </div>
      ),
      cell: (row) => (
        <ActionDropdown
          studentId={row.row.original.id}
          isActive={row.row.original.isActive}
          isOpen={openDropdownId === row.row.original.id}
          toggleDropdown={toggleDropdown}
          fetchAgain={fetchAgain}
          setFetchAgain={setFetchAgain}
        />
      ),
    }),
  ];

  const table = useReactTable({
    data: students || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="scrollHide overflow-y-auto mb-6">
      <table className="w-full bg-white rounded-t-lg">
        <thead className="sticky top-0 bg-white z-10">
          {table?.getHeaderGroups()?.map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className="px-2 xl:px-3 py-5 text-left text-base font-medium text-secondary capitalize"
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="bg-white divide-y divide-secondaryNeutral">
          {table.getRowModel().rows.map((row, index) => (
            <tr
              key={row.id}
              className={`hover:bg-secondaryNeutral ${index % 2 === 0 ? "bg-secondaryNeutral" : "bg-white"
                }`}
            >
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  className="px-2 xl:px-3 py-5 text-base text-secondaryVariant border-b border-secondaryNeutral"
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default StudentsTable;
