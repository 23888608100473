import * as React from "react";
import { IoCheckmarkOutline } from "react-icons/io5";
import { GoArrowRight } from "react-icons/go";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const SuccessfullySubscribedModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-[9999]">
      <div className="bg-white px-24 py-9 rounded-3xl shadow-lg w-[653px] flex items-center justify-center flex-col">
        <i className="text-white bg-successAlert rounded-full h-20 w-20 mb-6 text-center flex items-center justify-center">
          <IoCheckmarkOutline className="w-14 h-14" />
        </i>
        <h2 className="text-2xl text-secondary font-semibold">
          Welcome to <span className="italic">Foray</span>! 🎉
        </h2>
        <p className="text-2xl text-secondary mb-12">
          You've successfully subscribed.
        </p>
        <button
          className="btnPrimary max-w-[160px] flex justify-center gap-2"
          onClick={onClose}
        >
          Go to Foray
          <GoArrowRight className="w-5 h-5" />
        </button>
      </div>
    </div>
  );
};

export default SuccessfullySubscribedModal;
