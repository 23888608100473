import React, { useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import ClassroomDetailedListTable from "../tables/ClassroomDetailedListTable";
import { useQuery } from "@tanstack/react-query";
import { IClassroomSetup } from "../../types/IClassroomSetup.interface";
import { classroomSvc } from "../../services/classroom.service";
import { useLocation, useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import { forayRoutes } from "../../routes/routes";

const ClassroomDetailedList = () => {
  const [classrooms, setClassrooms] = useState<IClassroomSetup[]>([])
  const [searchQuery, setSearchQuery] = useState<string>("");
  const navigate = useNavigate();
  const location = useLocation()

  // const { data: classrooms } = useQuery<IClassroomSetup[]>({
  //   queryKey: ["classrooms", location?.pathname],
  //   queryFn: async () => {
  //     const res = await classroomSvc.fetchClassrooms();
  //     if (res?.ok) {
  //       return res.data?.existingClassroomSetups;
  //     }
  //     return [];
  //   },
  //   staleTime: 0,
  //   retryOnMount: true
  // });


  const getClassrooms = async () => {
    const res = await classroomSvc.fetchClassrooms()
    if (res?.ok) {
      setClassrooms(res.data?.existingClassroomSetups)
    }
  }

  useEffect(() => {
    getClassrooms()
  }, [])

  const filteredClassrooms = searchQuery ? classrooms?.filter((classroom) =>
    classroom?.classroomName?.toLowerCase().includes(searchQuery.toLowerCase())
    || classroom?.nickName?.toLowerCase().includes(searchQuery.toLowerCase())
  ) : classrooms;


  const handleAddClassroomDetail = () => {
    const withoutDetailClassroom = classrooms?.find(
      (classroom) =>
        !classroom?.nickName ||
        !classroom?.capacity?.licenseCapacity ||
        !classroom?.capacity?.physicalCapacity ||
        !classroom?.capacity?.studentPerTeacher ||
        !classroom?.settingType
    );
    navigate(forayRoutes.detailed_classroom_setup, { state: { classroomId: withoutDetailClassroom?._id } })
  };


  return (
    <>
      <div>
        <div className="bg-white p-3 rounded-xl flex items-center justify-between mb-6">
          <h2 className="text-2xl text-secondary font-semibold">
            Classroom-Details
          </h2>
          <div className="relative">
            <input
              type="search"
              placeholder="Search Classroom"
              className="border-[#CED2DA] border rounded-lg py-2 ps-3 pe-8"
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <span className="absolute right-2 top-3">
              <CiSearch className="w-5 h-5 text-secondaryVariant2" />
            </span>
          </div>
        </div>
        <ClassroomDetailedListTable classrooms={filteredClassrooms as IClassroomSetup[]} handleAddClassroomDetail={handleAddClassroomDetail} />

      </div>
    </>
  );
};

export default ClassroomDetailedList;
