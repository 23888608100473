import React from "react";
import { GoPlus } from "react-icons/go";
import { MdOutlineFileUpload } from "react-icons/md";
import { IoCloseOutline } from "react-icons/io5";
import { LuFileCheck } from "react-icons/lu";
import CompleteEmployeeSetup from "../Modals/CompleteEmployeeSetup";

const UploadEmployeeDocuments = () => {
  const [isModalOpen, setModalOpen] = React.useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);
  return (
    <>
      <div className="bg-white p-6 rounded-xl">
        <h2 className="text-xl text-secondary font-semibold mb-9">
          Upload Documents
        </h2>
        <div className="grid grid-cols-2 gap-9 mb-12">
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <span className="text-secondary text-base font-medium">
                Identity Document
              </span>
              <button className="text-statusColor text-lg font-medium px-3 flex items-center gap-1">
                <GoPlus className="w-6 h-6" />
                Add more
              </button>
            </div>
            <div className="border border-dashed border-secondaryVariant rounded-[10px] p-5 flex items-center gap-6">
              <button className="bg-secondaryVariant text-white p-3 rounded-lg">
                <MdOutlineFileUpload className="w-6 h-6" />
              </button>
              <div>
                <p className="text-base text-secondaryVariant">
                  Drag & Drop or{" "}
                  <strong className="text-primary">choose file</strong> to
                  upload
                </p>
                <span className="text-xs text-secondaryVariant">
                  Supported formats : Jpeg, pdf
                </span>
              </div>
            </div>
            <div className="flex items-center justify-between py-2 px-3 bg-secondaryNeutral border-[0.5px] border-secondaryVariant2 rounded-md">
              <p className="text-base text-secondary flex items-center gap-3">
                <i>
                  <LuFileCheck className="w-6 h-6" />
                </i>{" "}
                Supported formats : Jpeg, pdf
              </p>
              <button>
                <IoCloseOutline className="w-6 h-6 text-secondary" />
              </button>
            </div>
          </div>
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <span className="text-secondary text-base font-medium">
                Education Document{" "}
              </span>
              <button className="text-statusColor text-lg font-medium px-3 flex items-center gap-1">
                <GoPlus className="w-6 h-6" />
                Add more
              </button>
            </div>
            <div className="border border-dashed border-secondaryVariant rounded-[10px] p-5 flex items-center gap-6">
              <button className="bg-secondaryVariant text-white p-3 rounded-lg">
                <MdOutlineFileUpload className="w-6 h-6" />
              </button>
              <div>
                <p className="text-base text-secondaryVariant">
                  Drag & Drop or{" "}
                  <strong className="text-primary">choose file</strong> to
                  upload
                </p>
                <span className="text-xs text-secondaryVariant">
                  Supported formats : Jpeg, pdf
                </span>
              </div>
            </div>
          </div>
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <span className="text-secondary text-base font-medium">
                Signed Agreement{" "}
              </span>
              <button className="text-statusColor text-lg font-medium px-3 flex items-center gap-1">
                <GoPlus className="w-6 h-6" />
                Add more
              </button>
            </div>
            <div className="border border-dashed border-secondaryVariant rounded-[10px] p-5 flex items-center gap-6">
              <button className="bg-secondaryVariant text-white p-3 rounded-lg">
                <MdOutlineFileUpload className="w-6 h-6" />
              </button>
              <div>
                <p className="text-base text-secondaryVariant">
                  Drag & Drop or{" "}
                  <strong className="text-primary">choose file</strong> to
                  upload
                </p>
                <span className="text-xs text-secondaryVariant">
                  Supported formats : Jpeg, pdf
                </span>
              </div>
            </div>
          </div>
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <span className="text-secondary text-base font-medium">
                Other Document{" "}
              </span>
              <button className="text-statusColor text-lg font-medium px-3 flex items-center gap-1">
                <GoPlus className="w-6 h-6" />
                Add more
              </button>
            </div>
            <div className="border border-dashed border-secondaryVariant rounded-[10px] p-5 flex items-center gap-6">
              <button className="bg-secondaryVariant text-white p-3 rounded-lg">
                <MdOutlineFileUpload className="w-6 h-6" />
              </button>
              <div>
                <p className="text-base text-secondaryVariant">
                  Drag & Drop or{" "}
                  <strong className="text-primary">choose file</strong> to
                  upload
                </p>
                <span className="text-xs text-secondaryVariant">
                  Supported formats : Jpeg, pdf
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center gap-6">
          <button className="btnPrimary max-w-[182px]" onClick={openModal}>
            Save & Complete
          </button>
          <button className="btnSimple max-w-[182px]">Cancel</button>
          <CompleteEmployeeSetup isOpen={isModalOpen} onClose={closeModal} />
        </div>
      </div>
    </>
  );
};

export default UploadEmployeeDocuments;
