import { useQuery } from "@tanstack/react-query";
import { ITasksResponse } from "../types/ITaskGeneration.interface";
import { taskGenerationSvc } from "../services/taskgeneration.service";
import { TaskGenerationQueryKeyString } from "../enums/taskgenerationQueryKey.enum";

export const useDashboardTasks = () => {
  return useQuery<ITasksResponse, Error>({
    queryKey: [TaskGenerationQueryKeyString.DashboardTasks],
    queryFn: () => taskGenerationSvc.fetchAllTasks(),
  });
};
