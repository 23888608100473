import React, { FC, useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import InputField from "../inputsFields/InputField";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import ReactSelect from "../reactSelect/ReactSelect";
import { Field, ICategory, Section } from "../../types/ICategories.interface";
import { useUpdateSectionFilters } from "../../hooks/useUpdateSectionFilters";
import { ITaskGenerationFilters } from "../../types/ITaskGeneration.interface";
import { showErrorToast } from "../../../shared/utils/toast";

interface Props {
  selectedCard: ICategory;
  selectedSection: Section;
}

const FillTaskInformation: FC<Props> = ({ selectedCard, selectedSection }) => {
  const [openKeys, setOpenKeys] = useState<string[]>(["0"]); // Manage multiple open accordions
  const [filters, setFilters] = useState<{ [key: string]: any }>({}); // Store field values dynamically

  const { mutate: updateFilters, isSuccess: isUpdateSuccess } = useUpdateSectionFilters()

  const handleAccordionToggle = (key: string) => {
    setOpenKeys(
      (prevKeys) =>
        prevKeys.includes(key)
          ? prevKeys.filter((k) => k !== key) // Close the accordion
          : [...prevKeys, key] // Open the accordion
    );
  };

  const handleInputChange = (fieldName: string, value: any) => {
    setFilters((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };


  const handleSaveFilters = () => {
    // Mapping current filters to match the API interface
    const mappedFilters: ITaskGenerationFilters = {
      referenceDate: filters.referenceDate || null,
      status: filters.status || null,
      daysAfterReferenceDate: Number(filters.daysAfterReferenceDate) || 0,
      daysBeforeReferenceDate: Number(filters.daysBeforeReferenceDate) || 0,
      asOfDate: filters.asOfDate || null,
      classroom: filters.classroom || null,
      weekDay: filters.weekDay || null,
    };

    // Basic validation before API call
    if (!mappedFilters.referenceDate) {
      console.error("Reference Date is required.");
      showErrorToast("Please select a valid Reference Date.");
      return;
    }
    if (mappedFilters.daysAfterReferenceDate < 0 || mappedFilters.daysBeforeReferenceDate < 0) {
      console.error("Days cannot be negative.");
      showErrorToast("Days values cannot be negative.");
      return;
    }

    // API call
    updateFilters({
      categoryId: selectedCard?._id, // Replace with your actual category ID
      sectionId: selectedSection?._id,   // Replace with your actual section ID
      filters: mappedFilters,
    });
  };


  useEffect(() => {
    if (selectedSection?.appliedFilters?.length > 0) {
      const initialFilters = selectedSection.appliedFilters[0]; // Assuming the first object is applied
      setFilters(initialFilters);
    } else {
      setFilters({})
    }
  }, [selectedSection]);

  const renderField = (field: Field) => {
    if (field?.fieldType === "dropdown") {
      return (
        <ReactSelect
          key={filters?.[field.fieldName]}
          label={field.fieldLabel}
          placeholder={`Select ${field.fieldLabel}`}
          options={field.options}
          required={field.required}
          value={field?.options && field?.options?.find((opt) => opt.value === filters?.[field.fieldName])}
          onChange={(option) => handleInputChange(field.fieldName, option?.value)}
        />
      );
    }

    return (
      <InputField
        type={field?.fieldType}
        placeholder={`Enter ${field.fieldLabel}`}
        label={field.fieldLabel}
        required={field.required}
        value={filters?.[field.fieldName] || ""}
        onChange={(e) => handleInputChange(field.fieldName, e.target.value)}
      />
    );
  };

  return (
    <div className="bg-white p-6 rounded-xl mb-6">
      <Accordion activeKey={openKeys}>
        {/* Essential Fields */}
        <Accordion.Item eventKey="0" className="mb-7">
          <Accordion.Header
            onClick={() => handleAccordionToggle("0")}
            className="mb-7"
          >
            <h2 className="flex items-center gap-3 font-semibold text-xl">
              Essential{" "}
              {openKeys.includes("0") ? (
                <FaChevronUp className="text-secondaryVariant" />
              ) : (
                <FaChevronDown className="text-secondaryVariant" />
              )}
            </h2>
          </Accordion.Header>
          <Accordion.Body>
            <div className="grid grid-cols-3 gap-7">
              {selectedSection?.fields[0]?.essential?.map((field, index) => (
                <div key={index}>{renderField(field)}</div>
              ))}
            </div>
          </Accordion.Body>
        </Accordion.Item>

        {/* Advanced Fields */}
        <Accordion.Item eventKey="1">
          <Accordion.Header
            onClick={() => handleAccordionToggle("1")}
            className="mb-7"
          >
            <h2 className="flex items-center gap-3 font-semibold text-xl">
              Advanced{" "}
              {openKeys.includes("1") ? (
                <FaChevronUp className="text-secondaryVariant" />
              ) : (
                <FaChevronDown className="text-secondaryVariant" />
              )}
            </h2>
          </Accordion.Header>
          <Accordion.Body>
            <div className="grid grid-cols-4 gap-3">
              {selectedSection?.fields[0]?.advanced?.map((field, index) => (
                <div key={index}>{renderField(field)}</div>
              ))}
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <hr className="text-[#F6EAEA] my-7" />
      <div className="flex items-center gap-3">
        <button
          className="btnPrimary max-w-[182px]"
          onClick={handleSaveFilters}
        >
          Save
        </button>
        <button
          className="btnSimple max-w-[182px]"
          onClick={() => setFilters({})}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default FillTaskInformation;
