import { useQuery } from "@tanstack/react-query";
import { CenterQueryKeyString } from "../enums/centerQueryKey.enum";
import { foraySvc } from "../services/foray.service";

export const useCenterSetup = () => {
  return useQuery({
    queryKey: [CenterQueryKeyString.CenterSetup],
    queryFn: () => foraySvc.fetchCenterSetup(),
  });
};
