import { FC, useEffect, useState } from "react";
import { MdInfoOutline } from "react-icons/md";
import ReactSelect from "../reactSelect/ReactSelect";
import { IOptionS } from "../../types/ICenterSetup.interface";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  classroomOptions: IOptionS[];
  handleTransitionLastClassroomSave: () => void;
  handleInputChange: (
    name: string,
    value: string | number | IOptionS,
    index: number
  ) => void;
  lastIndex: number;
}

const SelectClassRoomModal: FC<ModalProps> = ({
  isOpen,
  onClose,
  classroomOptions,
  handleTransitionLastClassroomSave,
  handleInputChange,
  lastIndex,
}) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [selectedClassroom, setSelectedClassroom] = useState<IOptionS | null>(
    null
  );
  const [selectOptions, setSelectOptions] = useState<IOptionS[]>([]);

  const handleClassroomChange = (selectedOption: IOptionS | null) => {
    setSelectedClassroom(selectedOption);
    setIsDisabled(!selectedOption); // Disable button if no option is selected
    handleInputChange(
      "TransitionClassroom",
      selectedOption as IOptionS,
      lastIndex
    );
  };

  const handleSave = () => {
    handleTransitionLastClassroomSave();
    onClose();
  };

  const handleCancel = () => {
    setSelectedClassroom(null);
    onClose();
  };

  useEffect(() => {
    setSelectOptions([...classroomOptions, { label: "None", value: "none" }]);
    setSelectedClassroom(null)
  }, [classroomOptions]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-9 rounded-3xl shadow-lg w-[633px]">
        <div className="flex items-center flex-col justify-center text-center gap-9">
          <span className="block">
            {" "}
            <MdInfoOutline className="text-primary w-[88px] h-[88px]" />
          </span>
          <p className="text-2xl text-secondary font-semibold">
            The last classroom has not been assigned a transition classroom.
          </p>
          <p className="text-2xl text-secondary">
            You may select one now if applicable. Otherwise{" "}
            <strong>Select None</strong> below.
          </p>
          <div className="w-full px-4">
            {" "}
            <ReactSelect
              key={selectedClassroom?.value}
              label=""
              placeholder="Select Classroom"
              options={selectOptions}
              value={selectedClassroom}
              onChange={(selectedOption) => {
                handleClassroomChange(selectedOption as IOptionS);
              }}
            />
          </div>
          <div className="flex items-center justify-center gap-4">
            <button
              className={`btnPrimary max-w-[126px] ${isDisabled ? "opacity-50" : ""
                }`}
              onClick={handleSave}
              disabled={isDisabled}
            >
              Save
            </button>
            <button
              className="btnSimple max-w-[126px] flex justify-center whitespace-nowrap"
              onClick={handleCancel}
            >
              No, Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectClassRoomModal;
