import React from "react";
import DashboardWrapper from "../../../shared/components/layout/DashboardWrapper";
import { Link } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import ReactSelect from "../../../foray_module/components/reactSelect/ReactSelect";
import { VscNewFile } from "react-icons/vsc";
import CreateBonusPlanModal from "../../components/Modals/CreateBonusPlanModal";

const OvertimePayroll = () => {
  const [isModalOpen, setModalOpen] = React.useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);
  return (
    <>
      <DashboardWrapper>
        <div className="px-6 pt-6 overflow-y-scroll h-[calc(100vh-84px)] custom-scrollbar space-y-6">
          <Link
            to={"/"}
            className="text-sm text-secondaryVariant font-medium flex items-center gap-2"
          >
            <IoMdArrowBack className="w-5 h-5" />
            <span className="underline">Back to main</span>
          </Link>
          <h2 className="text-xl text-secondary font-semibold">Overtime</h2>
          <div className="grid grid-cols-3 gap-6">
            <div>
              <ReactSelect
                label={"Overtime Rate"}
                placeholder={"Time x 1.0"}
                required
              />
            </div>
            <div>
              <ReactSelect
                label={"Overtime Rate"}
                placeholder={"Time x 1.0"}
                required
              />
            </div>
          </div>
          <h2 className="text-xl text-secondary font-semibold">Payroll</h2>

          <div className="grid grid-cols-3 gap-6">
            <div>
              <ReactSelect
                label={"Pay Period"}
                placeholder={"2 weeks"}
                required
              />
            </div>
          </div>
          <h2 className="text-xl text-secondary font-semibold">
            Bonus Programs
          </h2>
          <button
            className="text-base text-statusColor flex items-center gap-1"
            onClick={openModal}
          >
            <VscNewFile className="w-6 h-6" />
            Create Bonus Plan
          </button>
          <CreateBonusPlanModal isOpen={isModalOpen} onClose={closeModal} />
        </div>
      </DashboardWrapper>
    </>
  );
};

export default OvertimePayroll;
