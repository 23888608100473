import { useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import { useLocation, useNavigate } from "react-router-dom";
import Breadcrumb from "../../../hr-setup_modules/components/Breadcrumb";
import DashboardWrapper from "../../../shared/components/layout/DashboardWrapper";
import ForayTabs from "../../components/forayTabs/ForayTabs";
import EssentialsClassroomTable from "../../components/tables/EssentialsClassroomTable";
import { forayRoutes } from "../../routes/routes";
import { classroomSvc } from "../../services/classroom.service";
import { IClassroomSetup } from "../../types/IClassroomSetup.interface";

const EssentialClassroomList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [classrooms, setClassrooms] = useState<IClassroomSetup[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");

  const breadcrumbItems = [
    { label: "Foray", link: forayRoutes.foray_screen },
    { label: "Classroom-Essentials", isCurrent: true },
  ];
  const handleSetupNewEssentialClassroom = () => {
    navigate(
      forayRoutes?.essential_classroom_setup + `?pr=${location.pathname}`
    );
  };

  const getClassroomSetup = async () => {
    const res = await classroomSvc.fetchClassrooms();
    if (res?.ok) {
      setClassrooms(res.data?.existingClassroomSetups);
    }
  };
  console.log("check class: ", classrooms);

  const filteredClassrooms = searchQuery ? classrooms?.filter((classroom) =>
    classroom?.classroomName?.toLowerCase().includes(searchQuery.toLowerCase())
    || classroom?.nickName?.toLowerCase().includes(searchQuery.toLowerCase())
  ) : classrooms;

  useEffect(() => {
    getClassroomSetup();
  }, []);
  return (
    <>
      <DashboardWrapper>
        <div className="px-6 py-5 bg-secondaryNeutral overflow-y-scroll h-[calc(100vh-85px)] custom-scrollbar">
          <Breadcrumb items={breadcrumbItems} />
          <ForayTabs />
          <button
            className="btnPrimary flex items-center gap-2 max-w-[310px] mb-9"
            onClick={handleSetupNewEssentialClassroom}
          >
            {classrooms?.length > 0 ? "Add New Classroom" : "Setup New Classroom-Essentials"}
          </button>
          <div className="bg-white p-3 rounded-xl flex items-center justify-between mb-6">
            <h2 className="text-2xl text-secondary font-semibold">
              Classroom-Essentials
            </h2>
            <div className="relative">
              <input
                type="search"
                placeholder="Search Classroom"
                className="border-[#CED2DA] border rounded-lg py-2 ps-3 pe-8"
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <span className="absolute right-2 top-3">
                <CiSearch className="w-5 h-5 text-secondaryVariant2" />
              </span>
            </div>
          </div>
          <EssentialsClassroomTable classrooms={filteredClassrooms as IClassroomSetup[]} fetchAgain={getClassroomSetup} />
          {/* <div className="flex items-center justify-between mb-7">
                        <h2 className="text-2xl text-secondary font-semibold">
                            Essential Classroom Information
                        </h2>
                        <div className="flex items-center text-statusColor">
                            Modify
                        </div>
                    </div> */}
          {/* <EssentialClassroomListTable /> */}
          {/* <AddNew
                        buttonText="Detailed Classrooms Setup"
                        route={"#" + `?pr=${location.pathname}`}
                    /> */}
        </div>
      </DashboardWrapper>
    </>
  );
};

export default EssentialClassroomList;
