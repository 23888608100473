import { useEffect, useState } from "react";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { PiNotepadLight } from "react-icons/pi";
import { ChecklistType } from "../../../hr-setup_modules/enums/checklist";
import { showErrorToast } from "../../../shared/utils/toast";
import { useCenterSetup } from "../../hooks/useCenterSetup";
import { useChecklist } from "../../hooks/useChecklist";
import { useCompleteChecklist } from "../../hooks/useCompleteDailyChecklist";
import { useDashboardTasks } from "../../hooks/useTasks";
import { useUpdateTasks } from "../../hooks/useUpdateTasks";
import { IChecklist } from "../../types/IChecklist.interface";
import { ITasks } from "../../types/ITaskGeneration.interface";
import { getTodayDayName } from "../../utils/functions/getDayOfWeek";
import { useCategories } from "../../hooks/useCateogories";

interface TimelineItem {
  id: string;
  time: string;
  type: "task" | "checklist";
  data: ITasks | IChecklist;
}

const ForayTracking = () => {
  const [checklistInitialsMap, setChecklistInitialsMap] = useState<{
    [key: string]: string;
  }>({});
  const [initialsMap, setInitialsMap] = useState<{ [key: string]: string }>({});
  const [operatingHours, setOperatingHours] = useState({
    startTime: "10:00",
    endTime: "20:00",
  });
  const [timeProgress, setTimeProgress] = useState<number>(0);

  const { data: tasksD } = useDashboardTasks();
  const { data: categories } = useCategories();
  const { data: checklist } = useChecklist();
  const { data: centerSetup } = useCenterSetup();
  const { mutate: checklistItemComplete } = useCompleteChecklist();
  const { mutate: updateTasks } = useUpdateTasks();

  const handleMarkAsDone = (task: ITasks) => {
    const initials = initialsMap[task._id];
    if (!initials?.trim()) {
      showErrorToast("Please enter initials before marking as done");
      return;
    }
    updateTasks({
      reqBody: {
        tasks: [{ _id: task._id, initials }],
      },
    });
  };

  const handleChecklistMarkAsDone = (checklistItem: IChecklist) => {
    const initials = checklistInitialsMap[checklistItem._id];
    if (!initials?.trim()) {
      showErrorToast("Please enter initials before marking as done");
      return;
    }
    checklistItemComplete({
      checklistId: checklistItem._id,
      initials,
    });
  };

  const convertTo12Hour = (time24: string) => {
    if (time24.includes("AM") || time24.includes("PM")) {
      return time24;
    }
    const [hours, minutes] = time24.split(":");
    const hour = parseInt(hours);
    const ampm = hour >= 12 ? "PM" : "AM";
    const hour12 = hour % 12 || 12;
    return `${hour12}:${minutes} ${ampm}`;
  };

  const getSortedTimes = () => {
    const times = new Set<string>();

    // Add task times
    tasksD?.data?.forEach((task) => {
      const category = categories?.data?.find(
        (cat: { _id: string }) => cat._id === task.categoryId?._id
      );
      if (category) {
        times.add(convertTo12Hour(category.scheduledTime));
      }
    });

    checklist?.data?.forEach((item) => {
      if (
        item.type === ChecklistType.Daily &&
        item.dayOfWeek === getTodayDayName() &&
        item.time
      ) {
        times.add(item.time);
      }
    });

    return Array.from(times).sort((a, b) => {
      const timeA = new Date(`1970/01/01 ${a}`);
      const timeB = new Date(`1970/01/01 ${b}`);
      return timeA.getTime() - timeB.getTime();
    });
  };

  const getTasksForTime = (time: string) => {
    return (
      tasksD?.data?.filter((task) => {
        const category = categories?.data?.find(
          (cat) => cat._id === task.categoryId?._id
        );
        return category && convertTo12Hour(category.scheduledTime) === time;
      }) || []
    );
  };

  const getChecklistsForTime = (time: string) => {
    return (
      checklist?.data?.filter(
        (item) =>
          item.type === ChecklistType.Daily &&
          item.dayOfWeek === getTodayDayName() &&
          item.time === time
      ) || []
    );
  };

  // Combine tasks and checklists into a single timeline
  const getTimelineItems = (): Record<string, TimelineItem[]> => {
    const timeline: Record<string, TimelineItem[]> = {};

    // Add tasks to timeline
    if (tasksD?.data && categories?.data) {
      tasksD.data.forEach((task) => {
        const category = categories?.data?.find(
          (cat) => cat._id === task.categoryId?._id
        );
        if (category) {
          const time12h = convertTo12Hour(category.scheduledTime);
          if (!timeline[time12h]) timeline[time12h] = [];
          timeline[time12h].push({
            id: task._id,
            time: time12h,
            type: "task",
            data: task,
          });
        }
      });
    }

    // Add checklist items to timeline
    if (checklist?.data) {
      const todayChecklists = checklist.data.filter(
        (item: IChecklist) =>
          item.type === ChecklistType.Daily &&
          item.dayOfWeek === getTodayDayName()
      );

      todayChecklists.forEach((item) => {
        if (item.time) {
          if (!timeline[item.time]) timeline[item.time] = [];

          timeline[item.time].push({
            id: item._id,
            time: item.time,
            type: "checklist",
            data: item,
          });
        }
      });
    }

    return timeline;
  };

  const getStatusClass = (item: TimelineItem): string => {
    if (item.type === "task") {
      const task = item.data as ITasks;
      if (task.completed) return "border-successAlert bg-successAlert";
    } else {
      const checklist = item.data as IChecklist;
      if (checklist.isCompleted) return "border-successAlert bg-successAlert";
    }

    const currentTime = new Date();
    const itemTime = new Date();
    const [hours, minutes] = item.time.split(":").map(Number);
    itemTime.setHours(hours, minutes);

    if (itemTime < currentTime) {
      return "border-dangerAlert bg-dangerAlert";
    }
    return "border-gray-400 bg-gray-400";
  };

  useEffect(() => {
    if (centerSetup?.data?.existingCenterSetup?.operatingHours) {
      setOperatingHours(centerSetup.data.existingCenterSetup.operatingHours);
    }
  }, [centerSetup]);

  useEffect(() => {
    const calculateTimeProgress = () => {
      const now = new Date();
      const currentTimeInMinutes = now.getHours() * 60 + now.getMinutes();
      const [startHours, startMinutes] = operatingHours.startTime
        .split(":")
        .map(Number);
      const [endHours, endMinutes] = operatingHours.endTime
        .split(":")
        .map(Number);
      const startTimeInMinutes = startHours * 60 + startMinutes;
      const endTimeInMinutes = endHours * 60 + endMinutes;

      if (
        currentTimeInMinutes < startTimeInMinutes ||
        currentTimeInMinutes > endTimeInMinutes
      ) {
        setTimeProgress(0);
        return;
      }

      const progress =
        ((currentTimeInMinutes - startTimeInMinutes) /
          (endTimeInMinutes - startTimeInMinutes)) *
        100;

      setTimeProgress(Math.min(Math.max(progress, 0), 100));
    };

    calculateTimeProgress();
    const interval = setInterval(calculateTimeProgress, 30000);
    return () => clearInterval(interval);
  }, [operatingHours]);

  const timelineItems = getTimelineItems();
  const sortedTimes = Object.keys(timelineItems).sort((a, b) => {
    const [aHours, aMinutes] = a.split(":").map(Number);
    const [bHours, bMinutes] = b.split(":").map(Number);
    return aHours * 60 + aMinutes - (bHours * 60 + bMinutes);
  });
  const convertTo24Hour = (time: string) => {
    const [timeStr, period] = time.split(" ");
    const [hours, minutes] = timeStr.split(":").map(Number);
    if (period === "PM" && hours !== 12) {
      return `${hours + 12}:${minutes}`;
    } else if (period === "AM" && hours === 12) {
      return `00:${minutes}`;
    }
    return `${hours}:${minutes}`;
  };

  const progressPercentage = (time: string) => {
    const time24 =
      time.includes("AM") || time.includes("PM") ? convertTo24Hour(time) : time;

    const operatingStartTime = "10:00";
    const operatingEndTime = "20:00";
    const [startHours, startMinutes] = operatingStartTime
      .split(":")
      .map(Number);
    const [endHours, endMinutes] = operatingEndTime.split(":").map(Number);
    const operatingStartMinutes = startHours * 60 + startMinutes;
    const operatingEndMinutes = endHours * 60 + endMinutes;
    const totalOperatingMinutes = operatingEndMinutes - operatingStartMinutes;

    const [hours, minutes] = time24.split(":").map(Number);
    const itemTotalMinutes = hours * 60 + minutes;
    const elapsedMinutes = itemTotalMinutes - operatingStartMinutes;

    if (elapsedMinutes <= 0) return 0;
    if (elapsedMinutes >= totalOperatingMinutes) return 100;

    return ((elapsedMinutes / totalOperatingMinutes) * 100).toFixed(2);
  };

  return (
    <div className="bg-white rounded-xl p-5">
      <div className="flex items-center justify-between mb-20">
        <div className="flex items-center gap-2">
          <PiNotepadLight className="w-6 h-6 text-statusColor" />
          <h3 className="text-xl text-secondary font-semibold flex items-center gap-1">
            Tracking{" "}
            <IoMdInformationCircleOutline className="w-5 h-5 text-secondaryVariant" />
          </h3>
        </div>
        <h3 className="text-lg font-bold text-[#484F61]">
          {new Date().toLocaleDateString("en-US", {
            weekday: "short",
            month: "short",
            day: "numeric",
          })}
        </h3>
      </div>

      <div className="flex items-start gap-[22px] mb-4">
        <h3 className="text-secondary text-xl font-medium">Tasks</h3>
        <div className="grid grid-cols-12 pr-[50px]">
          {getSortedTimes().map((time) => (
            <div key={`task-${time}`} className="flex mt-1">
              <ul className="mb-1 flex flex-wrap flex-col max-h-[90px]">
                {getTasksForTime(time).map((task) => (
                  <li key={task._id} className="mb-[0.5px] relative group">
                    <span
                      className={`w-4 h-4 border-[3px] rounded-full block ${task.completed
                          ? "border-successAlert bg-successAlert"
                          : "border-gray-400 bg-gray-400"
                        }`}
                    />
                    {/* Task Popup */}
                    <div className="absolute left-0 top-full mt-2 text-black min-w-[308px] opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-300 z-10">
                      <div className="absolute -top-0 left-4 w-4 h-4 bg-white rotate-45 transform origin-center shadow-lg z-[-1]" />
                      <div className="px-4 py-6 bg-white z-5 rounded-md shadow-lg">
                        <div className="flex items-center justify-between gap-2">
                          <p className="text-base text-secondary">
                            {task.categoryId?.title}
                          </p>
                          <p className="text-xs text-secondaryVariant">
                            {convertTo12Hour(
                              categories?.data?.find(
                                (cat) => cat._id === task.categoryId?._id
                              )?.scheduledTime || ""
                            )}
                          </p>
                        </div>
                        <p className="text-xs text-secondaryVariant">
                          Student: {task.studentId.firstName}{" "}
                          {task.studentId.lastName}
                        </p>
                        <div className="flex items-end">
                          <div className="flex-1">
                            <h4 className="text-secondary">Initials</h4>
                            <input
                              disabled={task.initials ? true : false}
                              type="text"
                              className="bg-white rounded-md border border-secondaryVariant w-[60px] h-[36px] p-2"
                              value={
                                initialsMap[task._id] !== undefined
                                  ? initialsMap[task._id]
                                  : task.initials || ""
                              }
                              onChange={(e) =>
                                setInitialsMap((prev) => ({
                                  ...prev,
                                  [task._id]: e.target.value,
                                }))
                              }
                            />
                          </div>
                          <button
                            className="bg-[#4CCF9A] text-white rounded-md min-w-[131px] h-[33px] flex items-center justify-center"
                            onClick={() => handleMarkAsDone(task)}
                          >
                            Mark as Done
                          </button>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>

      <div className="flex items-center gap-2 mb-4">
        <span className="text-sm text-secondaryVariant font-semibold w-[120px]">
          {convertTo12Hour(operatingHours.startTime)}
        </span>
        <div className="bg-secondaryNeutral rounded-[5px] h-[37px] w-full">
          <div
            className="relative bg-secondary rounded-[5px] h-[37px] transition-all duration-300"
            style={{ width: `${timeProgress}%` }}
          >
            <span className="text-sm text-white font-semibold absolute right-2 top-2">
              {new Date().toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </span>
          </div>
        </div>
        <span className="text-sm text-secondaryVariant font-semibold w-12">
          {convertTo12Hour(operatingHours.endTime)}
        </span>
      </div>
      <div className="flex items-start gap-[22px]">
        <h3 className="text-secondary text-xl font-medium">Checklist</h3>
        <div className="grid  flex-1 pr-[50px] relative">
          {getSortedTimes().map((time) => (
            <div
              key={`checklist-${time}`}
              className=" mt-1 absolute "
              style={{
                left: `${progressPercentage(time)}%`,
              }}
            >
              <div>
                <ul className="mb-1 flex flex-wrap flex-col max-h-[90px]">
                  {getChecklistsForTime(time).map((checklistItem) => (
                    <li
                      key={checklistItem._id}
                      className="mb-[0.5px] relative group"
                    >
                      <span
                        className={`w-4 h-4 border-[3px] rounded-full block ${checklistItem.isCompleted
                            ? "border-successAlert bg-successAlert"
                            : "border-gray-400 bg-gray-400"
                          }`}
                      />
                      {/* Checklist Popup */}
                      <div className="absolute left-0 top-full mt-2 text-black min-w-[308px] opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-300 z-10">
                        <div className="absolute -top-0 left-4 w-4 h-4 bg-white rotate-45 transform origin-center shadow-lg z-[-1]" />
                        <div className="px-4 py-6 bg-white z-5 rounded-md shadow-lg">
                          <div className="flex items-center justify-between gap-2">
                            <p className="text-base text-secondary">
                              {checklistItem.task}
                            </p>
                            <p className="text-xs text-secondaryVariant">
                              {checklistItem.time}
                            </p>
                          </div>
                          <p className="text-xs text-secondaryVariant">
                            {checklistItem.dayOfWeek}
                          </p>
                          <div className="flex items-end">
                            <div className="flex-1">
                              <h4 className="text-secondary">Initials</h4>
                              <input
                                disabled={
                                  checklistItem.completedBy ? true : false
                                }
                                type="text"
                                className="bg-white rounded-md border border-secondaryVariant w-[60px] h-[36px] p-2"
                                value={
                                  checklistInitialsMap[checklistItem._id] !==
                                    undefined
                                    ? checklistInitialsMap[checklistItem._id]
                                    : checklistItem.completedBy || ""
                                }
                                onChange={(e) =>
                                  setChecklistInitialsMap((prev) => ({
                                    ...prev,
                                    [checklistItem._id]: e.target.value,
                                  }))
                                }
                              />
                            </div>
                            <button
                              className="bg-[#4CCF9A] text-white rounded-md min-w-[131px] h-[33px] flex items-center justify-center"
                              onClick={() =>
                                handleChecklistMarkAsDone(checklistItem)
                              }
                            >
                              Mark as Done
                            </button>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

// Popup Components
const TaskPopup: React.FC<{
  task: ITasks;
  time: string;
  categories: any;
  initialsMap: { [key: string]: string };
  setInitialsMap: React.Dispatch<
    React.SetStateAction<{ [key: string]: string }>
  >;
  handleMarkAsDone: (task: ITasks) => void;
}> = ({
  task,
  time,
  categories,
  initialsMap,
  setInitialsMap,
  handleMarkAsDone,
}) => (
    <>
      <div className="flex items-center justify-between gap-2">
        <p className="text-base text-secondary">{task.categoryId?.title}</p>
        <p className="text-xs text-secondaryVariant">{time}</p>
      </div>
      <p className="text-xs text-secondaryVariant">
        Student: {task.studentId.firstName} {task.studentId.lastName}
      </p>
      <div className="flex items-end">
        <div className="flex-1">
          <h4 className="text-secondary">Initials</h4>
          <input
            disabled={task.initials ? true : false}
            type="text"
            className="bg-white rounded-md border border-secondaryVariant w-[60px] h-[36px] p-2"
            value={
              initialsMap[task._id] !== undefined
                ? initialsMap[task._id]
                : task.initials || ""
            }
            onChange={(e) =>
              setInitialsMap((prev) => ({ ...prev, [task._id]: e.target.value }))
            }
          />
        </div>
        <button
          className="bg-[#4CCF9A] text-white rounded-md min-w-[131px] h-[33px] flex items-center justify-center"
          onClick={() => handleMarkAsDone(task)}
        >
          Mark as Done
        </button>
      </div>
    </>
  );

const ChecklistPopup: React.FC<{
  checklist: IChecklist;
  checklistInitialsMap: { [key: string]: string };
  setChecklistInitialsMap: React.Dispatch<
    React.SetStateAction<{ [key: string]: string }>
  >;
  handleChecklistMarkAsDone: (checklist: IChecklist) => void;
}> = ({
  checklist,
  checklistInitialsMap,
  setChecklistInitialsMap,
  handleChecklistMarkAsDone,
}) => (
    <>
      <div className="flex items-center justify-between gap-2">
        <p className="text-base text-secondary">{checklist.task}</p>
        <p className="text-xs text-secondaryVariant">{checklist.time}</p>
      </div>
      <p className="text-xs text-secondaryVariant">{checklist.dayOfWeek}</p>
      <div className="flex items-end">
        <div className="flex-1">
          <h4 className="text-secondary">Initials</h4>
          <input
            disabled={checklist.completedBy ? true : false}
            type="text"
            className="bg-white rounded-md border border-secondaryVariant w-[60px] h-[36px] p-2"
            value={
              checklistInitialsMap[checklist._id] !== undefined
                ? checklistInitialsMap[checklist._id]
                : checklist.completedBy || ""
            }
            onChange={(e) =>
              setChecklistInitialsMap((prev) => ({
                ...prev,
                [checklist._id]: e.target.value,
              }))
            }
          />
        </div>
        <button
          className="bg-[#4CCF9A] text-white rounded-md min-w-[131px] h-[33px] flex items-center justify-center"
          onClick={() => handleChecklistMarkAsDone(checklist)}
        >
          Mark as Done
        </button>
      </div>
    </>
  );

export default ForayTracking;
