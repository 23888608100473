import React from "react";
import DashboardWrapper from "../../../shared/components/layout/DashboardWrapper";
import ForayDashboardCard from "../../components/forayDashboardComponents/ForayDashboardCard";
import ForayTracking from "../../components/forayDashboardComponents/ForayTracking";
import { Link } from "react-router-dom";
import { forayRoutes } from "../../routes/routes";
import { MdOutlineDashboardCustomize } from "react-icons/md";
import {
  RiFileList2Line,
  RiFocus3Line,
  RiMoneyDollarBoxLine,
} from "react-icons/ri";
import { TbUserEdit } from "react-icons/tb";
import { AiOutlineBars } from "react-icons/ai";
import { PiUserCirclePlusLight } from "react-icons/pi";
import { IoMdBook } from "react-icons/io";
import { HiMiniArrowTrendingUp, HiOutlineCircleStack } from "react-icons/hi2";
import { VscSettings } from "react-icons/vsc";
import { LuFileCheck } from "react-icons/lu";
import ForayModuleTabs from "./components/ForayModuleTabs";

const ForayDashboard = () => {
  return (
    <>
      <DashboardWrapper>
        <div className="p-5">
          <div className="mb-4">
            <ForayModuleTabs />
          </div>
          <div className="text-secondary font-semibold text-2xl my-4">
            Dashboard
          </div>
          <ForayDashboardCard />
          <ForayTracking />
        </div>
      </DashboardWrapper>
    </>
  );
};

export default ForayDashboard;
