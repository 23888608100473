import UploadHandbook from './UploadHandbook'
import CustomizeHandbook from './CustomizeHandbook'

const HandBookEmployee = () => {
  const handleViewClick = () => {
    alert("Viewing handbook!");
  };
  return (
    <div className='space-y-6'>
           <ul className="list-decimal list-inside"> 
              <li>You may use our <strong>Child Care <i className='text-primary'>Forward!</i> (CCF)</strong> Employee Handbook <strong>OR</strong> Upload your own.</li> 
              <li>You can <strong>Edit/Modify</strong> our CCF Handbook and customize for your center.</li> 
              <li>Please <strong>VIEW</strong> our CCF Employee Handbook before deciding.</li>
           </ul>
           <UploadHandbook 
              fileName="CCF_Employee_Handbook.docx" 
              onViewClick={handleViewClick} 
            />
           <CustomizeHandbook/>
    </div>
  )
}

export default HandBookEmployee