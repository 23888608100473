import { useQuery } from "@tanstack/react-query";
import { StripeQueryKeyString } from "../enums/stripeQueryKeyString";
import { stripeSvc } from "../services/stripe.service";

export const useMySubscription = (userId?:string) => { 
  return useQuery({
    queryKey: [StripeQueryKeyString.MySubscription,userId],
    queryFn: () => stripeSvc.fetchMySubsciption(),
    enabled: !!userId
  });
};
