import React from "react";
import DashboardWrapper from "../../components/layout/DashboardWrapper";
import CustomTabs from "../../../foray_module/components/tabsLinks/CustomTabs";
import Complimentary from "./Complimentary";
import ActivePlan2 from "./ActivePlan2";
import { FiChevronRight } from "react-icons/fi";
import ActivePlan1 from "./ActivePlan1";

const MySubscription = () => {
  const tabsData = [
    {
      eventKey: "profile",
      label: "Profile",
      content: <div></div>,
    },
    {
      eventKey: "subscription",
      label: "Subscription",
      content: <div></div>,
    },
  ];
  return (
    <>
      <DashboardWrapper>
        <div className="px-8 pt-8">
          <div className="mb-9">
            <CustomTabs tabs={tabsData} defaultActiveKey={"subscription"} />
          </div>
          <div>
            <h2 className="text-lg text-secondary font-semibold mb-6">
              My Subscription
            </h2>
            <Complimentary />
            <ActivePlan1 />
            <ActivePlan2 />
            <div className="bg-white p-6 border border-secondaryVariant2 flex items-center justify-between rounded-2xl">
              <h3 className="text-xl text-secondary font-semibold">
                Payment Method
              </h3>
              <button className="bg-primary text-white rounded-full p-1">
                <FiChevronRight className="w-4 h-4" />
              </button>
            </div>
          </div>
        </div>
      </DashboardWrapper>
    </>
  );
};

export default MySubscription;
