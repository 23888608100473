import { useQuery } from "@tanstack/react-query";
import { StripeQueryKeyString } from "../enums/stripeQueryKeyString";
import { stripeSvc } from "../services/stripe.service";

export const useMySubscription = () => { 
  return useQuery({
    queryKey: [StripeQueryKeyString.MySubscription],
    queryFn: () => stripeSvc.fetchMySubsciption(),
  });
};
