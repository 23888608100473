import { useMutation, useQueryClient } from "@tanstack/react-query";
import { showSuccessToast } from "../../shared/utils/toast";
import { ChecklistQueryKeyString } from "../enums/checklistQueryKey.enum";
import { checklistSvc } from "../services/checklist.service";
import { IMonthlyChecklistUpdate } from "../types/IChecklist.interface";

export const useUpdateMonthlyChecklist = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      itemId,
      reqBody,
    }: {
      itemId: string;
      reqBody: IMonthlyChecklistUpdate;
    }) => checklistSvc.updateMonthlyChecklistItem(itemId, reqBody),
    onError: (err) => {
      console.error("error:", err);
    },
    onSuccess: (response) => {
      if (response?.ok) {
        showSuccessToast("Checklist item updated successfully");
        queryClient.invalidateQueries({
          queryKey: [ChecklistQueryKeyString.MonthlyChecklist],
        });
      }
    },
  });
};
