import React from "react";
import { Tab, Nav } from "react-bootstrap";

interface TabItem {
  eventKey: string;
  label: string;
  content: React.ReactNode;
}

interface tabsDefault {
  tabs: TabItem[];
  defaultActiveKey: string;
}


const CustomTabs = ({ tabs, defaultActiveKey }:tabsDefault) => {
  return (
    <Tab.Container id="reusable-tabs" defaultActiveKey={defaultActiveKey}>
    <Nav variant="pills" className="flex items-center border-b border-secondaryVariant2 mb-6">
      {tabs.map((tab) => (
        <Nav.Item key={tab.eventKey}>
          <Nav.Link
            eventKey={tab.eventKey}
            className="inline-flex items-center gap-2 px-3 pb-3 text-lg [&.active]:text-primary [&.active]:font-medium border-b-[2px] border-transparent [&.active]:border-primary hover:text-primary hover:border-primary"
          >
            {tab.label}
          </Nav.Link>
        </Nav.Item>
      ))}
    </Nav>
    <Tab.Content>
      <div className="custom-scrollbar">
        {tabs.map((tab) => (
          <Tab.Pane
            key={tab.eventKey}
            eventKey={tab.eventKey}
            className="hidden opacity-0 [&.show]:block [&.show]:opacity-100 duration-300 transition-opacity ease-linear"
          >
            {tab.content}
          </Tab.Pane>
        ))}
      </div>
    </Tab.Content>
  </Tab.Container>
  )
}

export default CustomTabs