import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "../../../../../node_modules/react-datepicker/dist/react-datepicker.module.css";

interface Props {
  value?: Date | null;
  placeholder?: string;
  onChange?: (date: Date | null) => void;
  minDate?: Date;
  maxDate?: Date;
  isMonday?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  disabled?: boolean;
}

const ReactDatePicker: React.FC<Props> = ({ value, placeholder, onChange, minDate, maxDate, isMonday, onFocus, onBlur, disabled }) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const handleDateChange = (date: Date | null) => {
    setStartDate(date);
    onChange?.(date);
  };

  useEffect(() => {
    if (!value) return
    setStartDate(value)
  }, [value])

  const handleIsMonday = (date: Date) => {
    return date.getDay() === 1;
  };
  return (
    <DatePicker
      className="border border-secondaryVariant2 rounded-lg px-3 py-3.5 w-full focus:outline-none "
      placeholderText={placeholder}
      selected={startDate}
      onChange={handleDateChange}
      minDate={minDate}
      maxDate={maxDate}
      filterDate={isMonday ? handleIsMonday : undefined}
      onFocus={onFocus}
      onBlur={onBlur}
      disabled={disabled}
    />
  );
};

export default ReactDatePicker;
