import React, { useMemo } from "react";
import ApexCharts from "react-apexcharts";
import { ApexOptions } from "apexcharts";

interface RegistrationChartProps {
  weekData: any[];
  dayData: any[]; 
  title: string;
  barcolor: string;
  linecolor: string;
}

const RegistrationChartYearly: React.FC<RegistrationChartProps> = ({
  dayData,
  title,
  barcolor,
  linecolor,
}) => {

  const { barData, lineData, labels } = useMemo(() => {
    const barData: number[] = []; 
    const lineData: number[] = []; 
    const labels: string[] = Array(53).fill(""); 

    let monthTotal = 0;
    let currentMonth = "";
    let cumulativeTotal = 0;

    dayData?.forEach((item, index) => {
      const date = new Date(item.weekEnd); 
      const month = date.toLocaleString("default", { month: "short" }); 
      const isStartOfMonth = date.getDate() === 1;
      const isLastWeekOfMonth = (date: Date) => {
        const nextDay = new Date(date);
        nextDay.setDate(date.getDate() + 1);
        return nextDay.getMonth() !== date.getMonth(); 
      };

      const dailyValue =
        title === "Center Registration"
          ? item.weeklyRegistrations.centers
          : title === "Teacher Registration"
          ? item.weeklyRegistrations.teachers
          : item.weeklyRegistrations.parents;

      const totalValue =
        title === "Center Registration"
          ? item.totalRegistrationsTillDay.centers
          : title === "Teacher Registration"
          ? item.totalRegistrationsTillDay.teachers
          : item.totalRegistrationsTillDay.parents;


      const lastWeekOfMonth = isLastWeekOfMonth(date);

      if (lastWeekOfMonth) {
        labels[index] = month; 
        barData.push(monthTotal); 
        monthTotal = 0
      } else {
        labels[index] = ""; 
        barData.push(0); 
      }

      currentMonth = month;
      monthTotal += dailyValue; 
      cumulativeTotal = totalValue; 
      lineData.push(cumulativeTotal); 
    });


    if (currentMonth !== "") {
      barData.push(monthTotal);
    }

    return { barData, lineData, labels };
  }, [dayData, title]);

  const options: ApexOptions = useMemo(() => {
    return {
      series: [
        {
          name: "Total Registrations in this Month",
          type: "column",
          data: barData,
        },
        {
          name: "Total Registrations till Week",
          type: "line",
          data: lineData,
        },
      ],
      chart: {
        height: 300,
        type: "line",
        toolbar: {
          show: false,
        },
      },
      stroke: {
        width: [0, 4],
        curve: "smooth",
      },
      dataLabels: {
        enabled: false, // Disable data labels on the line (numbers)
      },
      markers: {
        size: 5, // Size of the dots
        colors: [linecolor], // Dot color (same as the line color)
        strokeColor: linecolor, // Border color for dots
        hover: {
          size: 8, // Size of dot when hovered
        },
      },
      tooltip: {
        enabled: true, // Enable tooltip to show when hovering
        shared: false, // Only show data for the hovered point
        intersect: true, // Only show tooltip for the exact point
        y: {
          formatter: (value: number) => `${value} Registrations`, // Format the tooltip
        },
      },
      labels: labels,
      xaxis: {
        categories: labels,
        title: {
          text: "Months",
        },
        tickAmount: labels.length, // Ensure the x-axis stops at December
      },
      yaxis: [
        {
          title: {
            text: "Registrations",
          },
        },
        {
          opposite: true,
          title: {
            text: "Cumulative Registrations",
          },
        },
      ],
      legend: {
        show: false,
      },
      colors: [barcolor, linecolor],
    };
  }, [barData, lineData, labels, barcolor, linecolor]);

  return (
    <div id="chart" className="bg-white rounded-xl p-4">
      <h4 className="text-xl font-semibold text-secondary mb-4">{title}</h4>
      <ApexCharts
        options={options}
        series={options.series}
        type="line"
        height={350}
      />
    </div>
  );
};

export default RegistrationChartYearly;
