import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { ccfRoutes } from "../../modules/ccfWebsite_module/routes/routes";
import { useMySubscription } from "../../modules/shared/hooks/useMySubscription";
import { useSubscription } from "../../modules/shared/hooks/useSubscription";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

interface Props {
  isForayLandingRoute?: boolean;
}

const SubscriptionProtection: React.FC<Props> = ({
  isForayLandingRoute = false,
}) => {
  const { User } = useSelector((state: RootState) => state?.auth);
  const { data: userSubscriptions, isLoading: isLoadingSubscriptions } =
    useMySubscription(User?._id);
  const subscriptionId = userSubscriptions?.data[0]?.subscriptionId;

  const { data: subscription, isLoading: isLoadingSubscription } =
    useSubscription(subscriptionId);

  const hasActiveSubscription =
    subscription?.data?.status === "active" ||
    subscription?.data?.status === "trialing";

  if (isLoadingSubscriptions || isLoadingSubscription) {
    return <div></div>;
  }

  // Allow access to landing route without subscription
  if (isForayLandingRoute) {
    return <Outlet />;
  }

  // Check if user has an active subscription
  if (!hasActiveSubscription) {
    return <Navigate to={ccfRoutes.home} />;
  }

  return <Outlet />;
};

export default SubscriptionProtection;
