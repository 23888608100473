import { FaRegTrashAlt } from "react-icons/fa";
import { IoEyeOutline } from "react-icons/io5";
import { MdOutlineModeEdit } from "react-icons/md";

interface UploadHandbookProps {
  fileName?: string;
  onViewClick?: () => void;
  isModified?:boolean
}

const UploadHandbook: React.FC<UploadHandbookProps> = ({ fileName, onViewClick,isModified }) => {
  return (
    <div className="flex py-2 px-3 rounded-lg border border-[#BEBFCD] bg-[#f2f2f2] items-center gap-4">
      <div className="rounded-lg w-[77px] h-[77px] bg-[#d9d9d9]"></div>
      <div className="flex-1">
        <p className="line-clamp-1 text-lg text-secondary">{fileName}</p>
      </div>
      <div className="flex items-center gap-4">
      <button 
        className="bg-white rounded-lg px-3 py-2 min-h-[48px] inline-flex items-center justify-center gap-2"
        onClick={onViewClick}
      >
        <IoEyeOutline className="text-primary" />View
      </button>
{isModified  ? (<>
 <button 
        className="bg-white rounded-lg px-3 py-2 min-h-[48px] inline-flex items-center justify-center gap-2 text-statusColor"
        onClick={onViewClick}
      >
        <MdOutlineModeEdit className="text-statusColor" /> Edit/Modify
      </button>
      <button className="bg-white  w-8 h-8 rounded-full inline-flex items-center justify-center text-[#DB3B21]">
        <FaRegTrashAlt size={18}/>
      </button></>
    ) : null}
      
      </div>
    </div>
  );
};

export default UploadHandbook;
