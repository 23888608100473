import React from "react";

const ActivePlan2 = () => {
  return (
    <>
      <div className="bg-white rounded-2xl border border-secondaryVariant2 p-6 mb-6">
        <div className="flex justify-between items-center mb-6">
          <h3 className="text-lg text-secondary font-semibold">
            Active Plan 2
          </h3>
          <button className="text-base text-dangerAlert underline">
            Cancel
          </button>
        </div>
        <div className="flex items-stretch justify-between">
          <div className="flex justify-between flex-col items-start">
            <div>
              <h3 className="text-[28px] text-primary font-semibold mb-2">
                <span className="italic">Foray</span> Basic{" "}
                <span className="font-normal text-secondary">(Monthly)</span>
              </h3>
              <div className="text-[#18C55F] border border-[#18C55F] text-xs font-medium bg-[#18C55F4D] bg-opacity-20 max-w-[160px] py-0.5 px-2 rounded-full">
                Trial ends April 9, 2025
              </div>
            </div>
            <button className="text-lg text-statusColor underline">
              Upgrade plan
            </button>
          </div>
          <div className="flex items-start justify-between flex-col gap-9">
            <h3 className="text-[28px] text-secondary font-semibold">
              US $20.00/<span className="font-normal">month</span>
            </h3>
            <p className="text-secondary text-base">
              After free trial ends April 25, 2025 this <br /> subscription will
              continue automatically.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActivePlan2;
