import React from "react";
import { Tabs } from "../../../enums/tabs.enum";

const tabs = Object.values(Tabs);

interface TabsFilterProps {
  activeTab: Tabs;
  setActiveTab: (tab: Tabs) => void;
    handleTabChange?: (tab: Tabs) => void;
  
}

const TabsFilter: React.FC<TabsFilterProps> = ({ activeTab, setActiveTab ,handleTabChange }) => {
  const handleTabClick = (tab: Tabs) => {
    setActiveTab(tab);
    if (handleTabChange) {
      handleTabChange(tab);  
    }
  };

  return (
    <ul className="flex items-center justify-end">
      {tabs.map((tab) => (
        <li
          key={tab}
          className="overflow-hidden first-of-type:rounded-tl-md first-of-type:rounded-bl-md last-of-type:rounded-tr-md last-of-type:rounded-br-md"
        >
          <button
            onClick={() => handleTabClick(tab)}
            className={`px-4 py-[6px] capitalize  ${
              activeTab === tab
                ? "bg-primary text-white"
                : "bg-white text-base text-secondary"
            }`}
          >
            {tab}
          </button>
        </li>
      ))}
    </ul>
  );
};

export default TabsFilter;
