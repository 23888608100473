import { useState } from "react";
import { GoArrowRight } from "react-icons/go";
import ForayLandingPageModal from "../../../medEvaluation_module/components/forayLandingPageModal/ForayLandingPageModal";
import HeroBanner from "../../../medEvaluation_module/components/medEvaluationBanner/HeroBanner";
import MainWrapper from "../../../shared/components/layout/MainWrapper";
import BeforeLoginModal from "../../../proffer_module/components/Modals/BeforeLogin";
import { RootState } from "../../../../store/store";
import { useSelector } from "react-redux";
import { showErrorToast } from "../../../shared/utils/toast";
import { useNavigate } from "react-router-dom";
import { sharedRoutes } from "../../../shared/routes/routes";

const FrLandingScreen = () => {
  // const [isOpenInvitationModal, setIsOpenInvitationModal] = useState(false)
  const [showBeforeLoginModal, setShowBeforeLoginModal] = useState(false);
  const { User } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate()

  const handleCloseBeforeLoginModal = () => {
    setShowBeforeLoginModal(false);
  };
  const handleOpenBeforeLoginModal = () => {
    setShowBeforeLoginModal(true);
  };

  const handleLearnMoreClick = () => {
    if (!User?._id) {
      handleOpenBeforeLoginModal();
    } else if (!User?.name || !User?.contactNumber) {
      showErrorToast(
        "Please complete your profile information in edit profile to PrOffer"
      );
    } else {
      navigate(sharedRoutes.pricing)
    }
  }
  
  return (
    <>
      <MainWrapper>
        <div className="mb-4 md:mb-10 xl:mb-16">
          <HeroBanner
            forayBanner={true}
            title="oray"
            highlightedText="F"
            paragraphText={""}
            bgClass="https://a360csastorage.blob.core.windows.net/childcare/987e454f-82e2-4445-923d-797c658ac3d9-forayLanding-bannerN.png"
          />{" "}
        </div>
        <div className="container mb-4 md:mb-10 xl:mb-16">
          <div className="grid lg:grid-cols-2 gap-2">
            <div className="space-y-6">
              <h2 className="text-[28px] text-secondary font-bold max-w-[503px]">
                Most <span className="text-primary italic">Forward!</span>{" "}
                Looking Software… <span className="uppercase"> It’s so Advanced - it’s simple </span> </h2>
              <p className="text-lg text-secondaryVariant">
                This is a quantum leap in helping Centers organize their
                enrollment.  Once you set up the relevant parameters, all
                student movements are automated - and for 365 days in advance!
                The read out is a simple dashboard.  You now have control of
                your center's enrollment - looking forward one whole year.
                What's more, you can OPTIMIZE enrollments and transitions and
                provide parents with precise dates of enrollment - always for
                365 days in advance.   This is just the beginning...there's lots
                more!
              </p>
              <button className="btnPrimary max-w-[160px] gap-3" onClick={handleLearnMoreClick}>
                Learn more <GoArrowRight className="w-5 h-5" />
              </button>
            </div>
            <div className="relative py-8">
              <img
                className="w-full relative z-10"
                src="https://a360csastorage.blob.core.windows.net/childcare/fe88f9df-54dd-479b-b618-b4c17fcc92f3-foray.png"
                alt="Enrollment Tracking"
              />
              <img src="https://a360csastorage.blob.core.windows.net/childcare/0bf3d07d-02d6-4db9-b244-a399aeafebe5-foraybackgroundimage.png" alt="" className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 xl:max-w-[908px] max-w-full" />
            </div>
          </div>
        </div>
        {showBeforeLoginModal && (
          <BeforeLoginModal
            handleBeforeLoginModal={handleOpenBeforeLoginModal}
            onClose={handleCloseBeforeLoginModal}
          />
        )}
        {/* <ForayLandingPageModal isOpen={isOpenInvitationModal} onClose={() => { setIsOpenInvitationModal(false) }} /> */}
      </MainWrapper>
    </>
  );
};

export default FrLandingScreen;
