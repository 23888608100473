import React from "react";
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ComposedChart,
  ResponsiveContainer,
} from "recharts";

// Define the type for the component props
interface WeeklyCenterRegistrationProps {
  title: string;
  containerColor: string;
  data: { name: string; barValue: number; lineValue: number }[];
}

const WeeklyCenterRegisteration: React.FC<WeeklyCenterRegistrationProps> = ({
  title,
  containerColor,
  data,
}) => {
  return (
    <div className={`bg-white rounded-xl p-4`}>
      <h4 className="text-xl font-semibold text-secondary mb-4">{title}</h4>
      <ResponsiveContainer width="100%" height={300}>
        <ComposedChart data={data}>
          <CartesianGrid
            strokeDasharray="3 3"
            horizontal={true}
            vertical={false}
          />
          <XAxis dataKey="name" axisLine={false} tickLine={false} />
          <YAxis
            yAxisId="left"
            orientation="left"
            axisLine={false}
            tickLine={false}
            tick={{ fill: containerColor }}
          />
          <YAxis
            yAxisId="right"
            orientation="right"
            axisLine={false}
            tickLine={false}
            tick={{ fill: containerColor }}
          />
          <Tooltip />
          <Bar
            yAxisId="left"
            dataKey="barValue"
            fill={containerColor}
            barSize={15}
          />
          <Line yAxisId="right" dataKey="lineValue" stroke={containerColor} />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default WeeklyCenterRegisteration;
