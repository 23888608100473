import React from "react";
import { TbClock24 } from "react-icons/tb";
import { HiOutlineCalendarDays } from "react-icons/hi2";
import { ChecklistType } from "../../../hr-setup_modules/enums/checklist";

interface IProps {
  handleChecklistSelect: (checklist: ChecklistType) => void;
  selectedChecklist: ChecklistType;
}

const ChecklistCard: React.FC<IProps> = ({
  handleChecklistSelect,
  selectedChecklist,
}) => {
  return (
    <>
      <div className="grid grid-cols-3 gap-4 my-[30px]">
        <div
          onClick={() => {
            handleChecklistSelect(ChecklistType.Daily);
          }}
          className={`text-start space-y-3 p-6 rounded-lg ${
            selectedChecklist === ChecklistType.Daily
              ? "bg-primary"
              : "bg-white"
          } border ${
            selectedChecklist === ChecklistType.Daily
              ? "border-secondary"
              : "border-white"
          }
          ${
            selectedChecklist === ChecklistType.Daily ?? "text-secondary"
          } hover:bg-primary  hover:border-secondary text-secondary relative`}
        >
          <div>
            <TbClock24 className="w-9 h-9" />
          </div>
          <h3 className="text-2xl font-semibold">Daily Checklist</h3>
        </div>
        <div
          onClick={() => {
            handleChecklistSelect(ChecklistType.Monthly);
          }}
          className={`text-start space-y-3 p-6 rounded-lg ${
            selectedChecklist === ChecklistType.Monthly
              ? "bg-primary"
              : "bg-white"
          } border ${
            selectedChecklist === ChecklistType.Monthly
              ? "border-secondary"
              : "border-white"
          }
          ${
            selectedChecklist === ChecklistType.Monthly ?? "text-secondary"
          } hover:bg-primary  hover:border-secondary text-secondary relative`}
        >
          <div>
            <HiOutlineCalendarDays className="w-9 h-9" />
          </div>
          <h3 className="text-2xl font-semibold">Monthly Checklist</h3>
        </div>
      </div>
    </>
  );
};

export default ChecklistCard;
