import React from "react";
import { MdArrowBackIos } from "react-icons/md";
import { RxArrowRight } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import { adventureRoutes } from "../../../adventures_module/routes/routes";
import {
  clientID,
  identityServerUserRegURI,
  redirectURI,
} from "../../../shared/constants/environment.constants";
import { USER_ROLES } from "../../../shared/enums/sharedEnums";

interface HeroBannerProps {
  paragraphText: string;
  showStats?: boolean;
  title?: string;
  highlightedText?: string;
  bgClass?: string;
  isButton?: boolean;
  backButton?: boolean;
  buttonText?: any;
  backButtonText?: any;
  forayBanner?: boolean;
  offerEvaluation?:boolean;
  offer?:string;
}

const HeroBanner: React.FC<HeroBannerProps> = ({
  paragraphText,
  showStats,
  title,
  highlightedText,
  bgClass,
  isButton,
  backButton,
  buttonText,
  backButtonText,
  forayBanner,
  offerEvaluation,
  offer
}) => {
  const navigate = useNavigate();
  const handleButtonClick = () => {
    let reg = "";
    if (buttonText.includes("Center")) reg = USER_ROLES.CENTER;
    if (buttonText.includes("Teacher")) reg = USER_ROLES.TEACHER;
    if (buttonText.includes("Parent")) reg = USER_ROLES.PARENT;

    window.location.replace(
      `${identityServerUserRegURI}${"?client_id=" + clientID}${
        "&redirect_URI=" + redirectURI
      }&role=${reg}`
    );
  };
  const handleBackButtonClick = () => {
    navigate(adventureRoutes.home);
  };

  return (
    <>
      <section
        className={`bg-no-repeat bg-cover bg-right`}
        style={{ backgroundImage: `url(${bgClass})` }}
      >
        <div className="lg:bg-banner min-h-[150px] md:h-[250px] lg:h-[320px] flex items-center bg-right-bottom bg-contain bg-no-repeat p-2 md-p-0">
          <div className="container">
            <div className="grid lg:grid-cols-3 gap-4">
              <div className="lg:col-span-2">
                <div className="lg:bg-none bg-bannerGradiant xl:p-0 p-4 xl:rounded-none rounded-lg xl:max-w-auto max-w-[525px]">
                  {backButton ? (
                    <button
                      className="text-primary flex items-center gap-2 text-lg mb-6"
                      onClick={handleBackButtonClick}
                    >
                      <MdArrowBackIos className="w-5 h-5" /> {backButtonText}
                    </button>
                  ) : null}
                  {title && (
                    <>
                      {!forayBanner ? (
                        <h2 className="text-secondary font-semibold xl:text-4xl lg:text-3xl text-2xl mb-2 lg:mb-6 max-w-[532px]">
                          {title}{" "}
                          <i className="text-primary">{highlightedText}</i>
                        </h2>
                      ) : (
                        <h2 className="text-secondary font-semibold xl:text-[115px] lg:text-3xl text-2xl mb-6 max-w-[532px]">
                          <i className="text-primary">{highlightedText}</i>
                          {title}{" "}
                        </h2>
                      )}
                    </>
                  )}
                  <p className="xl:text-18 text-base max-w-[470px]">
                    {paragraphText}
                  </p>
                  {isButton ? (
                    <button
                      className="btnPrimary flex items-center gap-4 justify-center max-w-[246px] mt-6"
                      onClick={handleButtonClick}
                    >
                      {buttonText} <RxArrowRight className="w-5 h-5" />
                    </button>
                  ) : null}

                  {offerEvaluation && <h4 className="text-2xl font-semibold mt-12">Only <span className="text-primary font-bold ">${offer}</span> per Evaluation</h4>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HeroBanner;
