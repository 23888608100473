import React, { useEffect, useState } from "react";
import {
  stripePremiumMonthlyPriceId,
  stripePremiumYearlyPriceId,
} from "../../constants/environment.constants";
import { stripeSvc } from "../../services/stripe.service";
import Sidebar from "../Header/dashboard-header/Sidebar";
import Topbar from "../Header/dashboard-header/Topbar";
import { useMySubscription } from "../../hooks/useMySubscription";
import { useSubscription } from "../../hooks/useSubscription";

const DashboardWrapper = ({ children }: { children: React.ReactNode }) => {
  const [isAbsolute, setIsAbsolute] = useState(false);

  const [isExpanded, setIsExpanded] = useState(true);
  const [width, setWidth] = useState("80px");
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 1200);

  const toggleSidebar = () => {
    if (isSmallScreen) {
      setIsAbsolute(!isExpanded);
      setWidth(isExpanded ? "80px" : "100%");
    } else {
      setIsExpanded(!isExpanded);
      setWidth(isExpanded ? "80px" : "290px");
    }
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    const handleResize = () => {
      const smallScreen = window.innerWidth <= 1200;
      setIsSmallScreen(smallScreen);

      if (!smallScreen) {
        setIsAbsolute(false);
        setWidth(isExpanded ? "290px" : "80px");
      } else {
        setIsAbsolute(isExpanded);
        setWidth(isExpanded ? "290px" : "80px");
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isExpanded]);

  const { data: userSubscriptions } = useMySubscription();
  const subscriptionId = userSubscriptions?.data?.[0]?.subscriptionId;

  // Fetch subscription details if subscriptionId exists
  const { data: subscription } = useSubscription(subscriptionId);

  const hasForaySubscription = React.useMemo(() => {
    if (!subscription?.data) return false;
    return (
      (subscription.data.status === "active" ||
        subscription.data.status === "trialing") &&
      (subscription.data.plan?.id === stripePremiumMonthlyPriceId ||
        subscription.data.plan?.id === stripePremiumYearlyPriceId)
    );
  }, [subscription]);

  return (
    <>
      <div className=" bg-natural min-h-screen">
        <Sidebar
          isAbsolute={isAbsolute}
          toggleSidebar={toggleSidebar}
          width={width}
          isExpanded={isExpanded}
          hasForaySubscription={hasForaySubscription}
        />
        <div
          className={` transition-all ease-in-out duration-300 ${
            isExpanded ? "pl-[290px]" : "pl-[80px]"
          } min-h-screen`}
        >
          <Topbar />
          {children}
        </div>
      </div>
    </>
  );
};

export default DashboardWrapper;
