import React from "react";
import DashboardWrapper from "../../../shared/components/layout/DashboardWrapper";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const GoToForay = () => {
  const renderTooltip = (props: any) => {
    return (
      <Tooltip
        id="button-tooltip"
        {...props}
        className="p-4 rounded-lg shadow-md max-w-[266px] bg-white ms-[210px] mb-[-30px]"
      >
        <div className="space-y-3">
          <h4 className="text-sm text-primary font-bold">
            Invited People Only!
          </h4>
          <p className="text-sm text-secondaryVariant">
            {" "}
            We are so sorry. Foray is currently by invitation only.{" "}
          </p>
        </div>
      </Tooltip>
    );
  };

  return (
    <DashboardWrapper>
      <div className="pt-10 flex justify-center items-center">
        <div className="max-w-[906px]">
          <h2 className="text-2xl text-secondary font-semibold mb-6">Foray</h2>
          <p className="text-lg text-secondaryVariant mb-10">
            Most <strong className="text-primary italic">Forward!</strong>{" "}
            Looking Software… it’s so Advanced, it’s simple.
          </p>
          <div className="grid grid-cols-2 gap-6">
            {/* Subscription Section */}
            <div className="p-6 bg-white rounded-lg space-y-3">
              <h3 className="text-2xl font-semibold text-secondary">
                Subscription
              </h3>
              <p className="text-base text-secondaryVariant">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>
              <button className="btnPrimary max-w-[119px]">Subscribe</button>
            </div>
            {/* Foray Section */}
            <div className="p-6 bg-white rounded-lg space-y-3">
              <h3 className="text-2xl font-semibold text-secondary">Foray</h3>
              <p className="text-base text-secondaryVariant">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>
              <OverlayTrigger
                placement="top"
                delay={{ show: 150, hide: 400 }}
                overlay={(props) => renderTooltip(props)}
              >
                <button className="btnPrimary max-w-[131px]">
                  Go to Foray
                </button>
              </OverlayTrigger>
            </div>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
};

export default GoToForay;
