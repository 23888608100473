import React from "react";
import { IoCloseOutline } from "react-icons/io5";
import { monthOptions } from "../../constants/monthOptions";
import {
  IMonthlyChecklist,
  IMonthlyReqBody,
} from "../../types/IChecklist.interface";
import { showErrorToast } from "../../../shared/utils/toast";
import Select from "react-select";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  checklist: IMonthlyReqBody | undefined;
  onMonthlyChecklistChange: (
    field: keyof IMonthlyChecklist,
    value: any
  ) => void;
  mutate: any;
  isEditMode: boolean;
}
const AddMonthlyTaskModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  checklist,
  onMonthlyChecklistChange,
  mutate,
  isEditMode,
}) => {
  if (!isOpen) return null;
  return (
    <>
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-[9999]">
        <div className="bg-white rounded-lg shadow-lg w-[464px]">
          <div className="flex items-center justify-between py-4 px-6">
            <h2 className="text-2xl font-semibold text-secondary">
              {isEditMode ? "Edit" : "Add"} Task
            </h2>
            <button
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700"
            >
              {" "}
              <IoCloseOutline className="w-6 h-6 text-secondary" />
            </button>
          </div>
          <div className="py-4 px-6 space-y-[18px]">
            <div>
              <label className="text-[10px] text-secondaryVariant mb-0.5">
                Select Month{" "}
              </label>
              {/* <input
                value={checklist?.month ?? ""}
                type="month"
                className="bg-[#F6F9FB] text-base text-secondaryVariant w-full h-[40px] rounded px-2"
                onChange={(e) => {
                  console.log(e.target.value);
                  onMonthlyChecklistChange("month", e.target.value);
                }}
              /> */}
              <Select
                className="newTask bg-[#F6F9FB] rounded h-10 ps-3"
                placeholder={"Select month"}
                options={monthOptions}
                value={monthOptions.find(
                  (option) => option.value === checklist?.month
                )}
                onChange={(selectedOption: any) =>
                  onMonthlyChecklistChange("month", selectedOption.value)
                }
              />
            </div>
            <div>
              <label className="text-[10px] text-secondaryVariant mb-0.5">
                Task*{" "}
              </label>
              <input
                onChange={(e) => {
                  console.log(e.target.value);
                  onMonthlyChecklistChange("task", e.target.value);
                }}
                type="text"
                placeholder="Task"
                value={checklist?.task ?? ""}
                className="bg-[#F6F9FB] text-base text-secondaryVariant w-full h-[40px] rounded px-2"
              />
            </div>
          </div>
          <div className="flex items-center justify-end gap-4 py-4 px-6">
            <button
              className="text-sm font-medium text-secondaryVariant2"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                if (checklist) {
                  if (!checklist.month || !checklist.task) {
                    showErrorToast("Please fill all the fields");
                    return;
                  }
                  if (isEditMode) {
                    mutate({ itemId: checklist._id, reqBody: checklist });
                  } else {
                    mutate(checklist);
                  }

                  onClose();
                }
              }}
              className="btnPrimary max-w-[95px]"
            >
              {isEditMode ? "Update" : "Add"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddMonthlyTaskModal;
