import React from "react";
import { RxCheckCircled } from "react-icons/rx";
import { VscEdit } from "react-icons/vsc";

const TodaySchedule = () => {
  return (
    <>
      <div className="border-[0.5px] border-secondaryVariant2 rounded-xl">
        <div className="grid grid-cols-2">
          <div className="flex items-center justify-between py-[18px] px-3 group hover:bg-primary rounded-ss-xl">
            <div className="text-lg text-secondary font-semibold flex items-center gap-2 group-hover:text-white uppercase">
              <i>
                <RxCheckCircled className="w-6 h-6" />
              </i>
              Today’s Schedule
            </div>
            <div>
              <button className="text-base text-statusColor font-medium flex items-center gap-1 group-hover:text-white">
                <VscEdit className="w-5 h-5" />
                Modify
              </button>
            </div>
          </div>
          <div className="flex items-center justify-between py-[18px] px-3 group hover:bg-primary rounded-tr-xl">
            <div className="text-lg text-secondary font-semibold group-hover:text-white uppercase">
              Ideal Schedule{" "}
            </div>
            <div>
              <button className="text-base text-statusColor font-medium flex items-center gap-1 group-hover:text-white">
                <VscEdit className="w-5 h-5" />
                Modify
              </button>
            </div>
          </div>
        </div>{" "}
      </div>
    </>
  );
};

export default TodaySchedule;
