import { Months, WeekDay } from "../enums/Foray.enums";
import {
  IChecklist,
  IDailyChecklist,
  IDailyChecklistUpdate,
  IDailyReqBody,
  IMonthlyChecklist,
  IMonthlyChecklistUpdate,
  IMonthlyReqBody,
  IResponseDailyChecklist,
} from "../types/IChecklist.interface";

import { apiForay } from "../utils/axios";

const fetchCheckLists = async () => {
  try {
    const res = await apiForay.get<IChecklist[]>(`/checklist`);
    if (res?.ok) {
      return { data: res?.data, ok: true };
    }
    return {
      ok: false,
      error: res?.data,
    };
  } catch (err) {
    console.log("error: ", err);
  }
};

const fetchDailyCheckLists = async (weekDay: WeekDay) => {
  try {
    const res = await apiForay.get<IResponseDailyChecklist>(
      `/checklist/daily?dayOfWeek=${weekDay}`
    );
    if (res?.ok) {
      return { data: res?.data, ok: true };
    }
    return {
      ok: false,
      error: res?.data,
    };
  } catch (err) {
    console.log("error: ", err);
  }
};

const fetchMonthlyCheckLists = async (month: Months) => {
  try {
    const res = await apiForay.get<IChecklist[]>(
      `/checklist/monthly?month=${month}`
    );
    if (res?.ok) {
      return { data: res?.data, ok: true };
    }
    return {
      ok: false,
      error: res?.data,
    };
  } catch (err) {
    console.log("error: ", err);
  }
};

const createDailyChecklist = async (reqBody: IDailyReqBody) => {
  try {
    const res = await apiForay.post<IDailyChecklist>(
      `/checklist/create/daily`,
      reqBody
    );
    if (res?.ok) {
      return { data: res?.data, ok: true };
    }
    return {
      ok: false,
      error: res?.data,
    };
  } catch (err) {
    console.log("error: ", err);
  }
};

const createMonthlyChecklist = async (reqBody: IMonthlyReqBody) => {
  try {
    const res = await apiForay.post<IMonthlyChecklist>(
      `/checklist/create/monthly`,
      reqBody
    );
    if (res?.ok) {
      return { data: res?.data, ok: true };
    }
    return {
      ok: false,
      error: res?.data,
    };
  } catch (err) {
    console.log("error: ", err);
  }
};

//might need different api for complete
const completeChecklistItem = async (checklistId: string, initials: string) => {
  try {
    const res = await apiForay.patch<IDailyChecklist | IMonthlyChecklist>(
      `checklist/${checklistId}/complete`,
      { name: initials }
    );
    if (res?.ok) {
      return { data: res?.data, ok: true };
    }
    return {
      ok: false,
      error: res?.data,
    };
  } catch (err) {
    console.log("error: ", err);
  }
};

const updateDailyChecklistItem = async (
  itemId: string,
  body: IDailyChecklistUpdate
) => {
  try {
    const res = await apiForay.patch<IDailyChecklist>(
      `checklist/daily/${itemId}`,
      body
    );
    if (res?.ok) {
      return { data: res?.data, ok: true };
    }
    return {
      ok: false,
      error: res?.data,
    };
  } catch (err) {
    console.log("error: ", err);
  }
};

const updateMonthlyChecklistItem = async (
  itemId: string,
  body: IMonthlyChecklistUpdate
) => {
  try {
    const res = await apiForay.patch<IMonthlyChecklist>(
      `checklist/monthly/${itemId}`,
      body
    );
    if (res?.ok) {
      return { data: res?.data, ok: true };
    }
    return {
      ok: false,
      error: res?.data,
    };
  } catch (err) {
    console.log("error: ", err);
  }
};

const deleteChecklistItem = async (itemId: string) => {
  try {
    const res = await apiForay.delete<IMonthlyChecklist>(`checklist/${itemId}`);
    if (res?.ok) {
      return { data: res?.data, ok: true };
    }
    return {
      ok: false,
      error: res?.data,
    };
  } catch (err) {
    console.log("error: ", err);
  }
};

export const checklistSvc = {
  fetchCheckLists,
  fetchDailyCheckLists,
  createDailyChecklist,
  createMonthlyChecklist,
  completeChecklistItem,
  fetchMonthlyCheckLists,
  updateDailyChecklistItem,
  updateMonthlyChecklistItem,
  deleteChecklistItem,
};
