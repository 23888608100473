import React, { useState } from "react";
import DashboardWrapper from "../../components/layout/DashboardWrapper";
import CustomTabs from "../../../foray_module/components/tabsLinks/CustomTabs";
import BillingPlans from "./BillingPlans";
import SuccessfullySubscribedModal from "./SuccessfullySubscribedModal";

const Subscription = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [subscriptionPeriod, setSubscriptionPeriod] = useState("monthly")
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);
  const tabsData = [
    {
      eventKey: "profile",
      label: "Profile",
      content: <div></div>,
    },
    {
      eventKey: "subscription",
      label: "Subscription",
      content: <div></div>,
    },
  ];

  const handleSubscriptionPeriod = (period: string) => {
    setSubscriptionPeriod(period)
  }

  console.log("cgeck: ", subscriptionPeriod)
  return (
    <>
      <DashboardWrapper>
        <div className="px-8 pt-8">
          <div className="mb-9">
            <CustomTabs tabs={tabsData} defaultActiveKey={"subscription"} />
          </div>
          <div>
            <h2 className="text-lg text-secondary font-semibold mb-9 ">
              Subscription
            </h2>
            <BillingPlans subscriptionPeriod={subscriptionPeriod} handleSubscriptionPeriod={handleSubscriptionPeriod} />
            <button
              className="btnPrimary max-w-[100px] mt-6"
              onClick={openModal}
            >
              Pay Now
            </button>
            <SuccessfullySubscribedModal
              isOpen={isModalOpen}
              onClose={closeModal}
            />
          </div>
        </div>
      </DashboardWrapper>
    </>
  );
};

export default Subscription;
