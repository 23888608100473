import React from "react";
import ReactDatePicker from "../../../shared/components/reactDatePicker/ReactDatePicker";
import { PiCalendarPlus } from "react-icons/pi";
import ReactSelect from "../../../foray_module/components/reactSelect/ReactSelect";
import {
  employmentTypes,
  jobTitles,
  salaryTypes,
} from "../../constants/dropdowns";

const ProfessionalInformation = () => {
  return (
    <>
      <div className="bg-white p-6 rounded-xl">
        <h2 className="text-xl text-secondary font-semibold mb-9">
          Fill Professional Information{" "}
        </h2>
        <div className="grid grid-cols-6 gap-3 mb-9">
          <div>
            <label className="text-secondaryVariant text-base mb-1 block">
              Employee ID{" "}
            </label>
            <input
              type="text"
              className="py-3.5 px-2 rounded-lg border-[0.5px] border-secondaryVariant2 bg-white w-full"
            />
          </div>
          <div>
            <label className="text-secondaryVariant text-base mb-1 block">
              Start Date{" "}
            </label>
            <div className="w-full relative">
              <ReactDatePicker placeholder="MM/DD/YYYY" maxDate={new Date()} />
              <PiCalendarPlus className="w-5 h-5 absolute right-3 top-4 pointer-events-none" />
            </div>
          </div>
          <div>
            <ReactSelect
              options={jobTitles}
              label="Job Title"
              placeholder="Job Title"
              required
            />
          </div>
          <div>
            <label className="text-secondaryVariant text-base mb-1 block">
              Color{" "}
            </label>
            <input
              type="text"
              className="py-3.5 px-2 rounded-lg border-[0.5px] border-secondaryVariant2 bg-white w-full"
            />
          </div>
          <div>
            <ReactSelect label="Classroom" placeholder="Classroom" required />
          </div>
          <div>
            <ReactSelect
              label="Working Days"
              placeholder="Working Days "
              required
            />
          </div>
          <div>
            <ReactSelect
              label="Full / Part Time"
              placeholder="Full / Part Time "
              required
              options={employmentTypes}
            />
          </div>
          <div>
            <ReactSelect
              label="Start Time"
              placeholder="Start Time "
              required
            />
          </div>
          <div>
            <ReactSelect label="End Time" placeholder="End Time " required />
          </div>
          <div>
            <ReactSelect
              label="Salary Type"
              placeholder="Salary Type "
              required
              options={salaryTypes}
            />
          </div>
          <div>
            <label className="text-secondaryVariant text-base mb-1 block">
              Salary Amount{" "}
            </label>
            <input
              type="text"
              className="py-3.5 px-2 rounded-lg border-[0.5px] border-secondaryVariant2 bg-white w-full"
            />
          </div>
          <div>
            <ReactSelect
              label="Overtime Rate"
              placeholder="Overtime Rate"
              required
              options={salaryTypes}
            />
          </div>
          <div>
            <ReactSelect
              label="PTO Allotted"
              placeholder="PTO Allotted "
              required
              options={salaryTypes}
            />
          </div>
        </div>
        <div className="flex items-center gap-3">
          <button className="btnPrimary max-w-[181px]">Save</button>
          <button className="btnSimple max-w-[181px]">Cancel</button>
        </div>
      </div>
    </>
  );
};

export default ProfessionalInformation;
