import { lazy, Suspense } from "react";
import { RouteObject } from "react-router-dom";
import ProtectedRoute from "../shared/components/RouteProtection";
import LoaderSpinner from "../shared/components/Loader/Loader";
import { adminRoutes } from "./routes/routes";
import AdminDashboard from "./pages/Dashboard/AdminDashboard";

const SuperAdminModuleRoutes: RouteObject[] = [
  {
    path: "/",
    element: <ProtectedRoute />,
    children: [
      {
        path: adminRoutes.dashboard,
        element: (
          <Suspense fallback={<LoaderSpinner />}>
            <AdminDashboard />
          </Suspense>
        ),
      },
    ],
  },
];

export default SuperAdminModuleRoutes;
