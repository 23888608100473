import React from "react";
import { Link, useLocation } from "react-router-dom";
import { forayRoutes } from "../../routes/routes";

const ForayTabs = () => {
  const pathName = useLocation().pathname;
  return (
    <>
      <div>
        <div className="sm:hidden">
          <label htmlFor="Tab" className="sr-only">
            Tab
          </label>
          <select
            id="Tab"
            className="w-full rounded-md border-secondaryVariant2"
          >
            <option selected>Center </option>
            <option>Classroom</option>
            <option>Student</option>
            <option>Hand Book</option>
          </select>
        </div>

        <div className="hidden sm:block mb-4 md:mb-7 xl:mb-9 ">
          <div className="border-b border-secondaryVariant2 pb-[1px]">
            <nav className="-mb-px flex" aria-label="Tabs">
              <Link
                to={forayRoutes.center_view}
                className={`shrink-0 pe-3 py-3 text-base font-medium text-start ${
                  pathName === forayRoutes.center_view
                    ? "border-b-primary border-b-2 text-primary "
                    : "border-b-2  text-secondaryVariant "
                } hover:border-b-primary hover:text-primary`}
                aria-current="page"
              >
                Center
              </Link>
              <Link
                to={forayRoutes.essential_classroom_list}
                className={`shrink-0 px-3 py-3 text-base font-medium text-start ${
                  pathName === forayRoutes.essential_classroom_list
                    ? "border-b-primary border-b-2 text-primary "
                    : "border-b-2  text-secondaryVariant "
                } hover:border-b-primary hover:text-primary`}
                aria-current="page"
              >
                Classroom-Essentials
              </Link>
              <Link
                to={forayRoutes.classroom_detailed}
                className={`shrink-0 px-3 py-3 text-base font-medium text-start ${
                  pathName === forayRoutes.classroom_detailed
                    ? "border-b-primary border-b-2 text-primary "
                    : "border-b-2  text-secondaryVariant "
                } hover:border-b-primary hover:text-primary`}
                aria-current="page"
              >
                Classroom-Details
              </Link>
              <Link
                to={forayRoutes.student_list}
                className={`shrink-0 px-3 py-3 text-base font-medium text-start ${
                  pathName === forayRoutes.student_list
                    ? "border-b-primary border-b-2 text-primary "
                    : "border-b-2  text-secondaryVariant "
                } hover:border-b-primary hover:text-primary`}
                aria-current="page"
              >
                Students
              </Link>
              {/* <Link
                to={forayRoutes.student_list}
                className={`shrink-0 px-3 py-3 text-base font-medium text-start ${
                  pathName === forayRoutes.student_list
                    ? "border-b-primary border-b-2 text-primary "
                    : "border-b-2  text-secondaryVariant "
                } hover:border-b-primary hover:text-primary`}
                aria-current="page"
              >
                Hand Book
              </Link> */}
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForayTabs;
