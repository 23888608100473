import React from "react";

const EssentialCharacterCard = () => {
  return (
    <>
      <div className="bg-white rounded-xl p-6 text-center flex flex-col items-center space-y-6 max-w-[312px]">
        {" "}
        <img
          className="rounded-full w-[160px] h-[160px] object-cover"
          src="https://a360csastorage.blob.core.windows.net/childcare/518662de-8b71-4c1e-a4fb-9e2b6f924e81-fileName-season-title-img.png"
          alt="season"
        />
        <div>
          <h3 className="text-lg text-secondary font-semibold mb-5">
            Hi, I’m Lucas.
          </h3>
          <p className="text-base text-secondaryVariant">
            I am Enzo’s best friend, at least I think so! Well, you have reached
            the most critical part of the setup. Please do follow the
            instructions on the left very carefully. This software is amazing
            and will essentially do all your work for you. BUT, it needs to be
            setup correctly. By the way, AFTER finishing your setup, check me
            out in Episode 1 of Adventures. This Enzo, hmmm, interesting
            character!
          </p>
        </div>
      </div>
    </>
  );
};

export default EssentialCharacterCard;
