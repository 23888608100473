import React from "react";
import { Link } from "react-router-dom";

interface NavItem {
  label: string;
  to: string;
  isActive: boolean;
}

interface ReusableNavProps {
  items: NavItem[];
}

const NavItemTabs: React.FC<ReusableNavProps> = ({ items }) => {
  return (
    <nav className="-mb-px flex" aria-label="Tabs">
      {items.map((item) => (
        <Link
          key={item.to}
          to={item.to}
          className={`shrink-0 px-3 py-3 text-base font-medium text-start ${
            item.isActive
              ? "border-b-primary border-b-2 text-primary"
              : "border-b-2 text-secondaryVariant"
          } hover:border-b-primary hover:text-primary`}
          aria-current={item.isActive ? "page" : undefined}
        >
          {item.label}
        </Link>
      ))}
    </nav>
  );
};

export default NavItemTabs;
