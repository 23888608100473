import { TooltipComponent } from "../../../shared/components/tooltip/Tooltip";
import { IClassroomSetup } from "../../types/IClassroomSetup.interface";
import { MdInfoOutline } from "react-icons/md";

interface Options {
  value: string;
  label: string;
}
interface IProps {
  classrooms: IClassroomSetup[];
  classroomOptions: Options[];
  autoTransition: boolean;
  toggleAutoTransition: () => void;
}
const tooltips = {
  // baProgram: {
  //   id: "baProgram-tooltip",
  //   content: `<p>This classroom is in BA program. To assign part-time sessions to this classroom, first remove it from BA program through classroom detailed setup.</p>`,
  // },
  studentTransitions: {
    id: "studentTransitions",
    content: `<p>We strongly recommend you choose Automated until you are familiar with how the software works.
</p>`,
  },
};
const EssentialTransition: React.FC<IProps> = ({
  classrooms,
  classroomOptions,
  autoTransition,
  toggleAutoTransition,
}) => {
  return (
    <div>
      <h3 className="text-secondary font-semibold text-xl mb-3">Section 3</h3>
      <div className="bg-white p-6 rounded-xl space-y-3">
        <div className="flex gap-2 items-center mb-6">
          <h2 className="text-xl font-medium  flex items-start gap-2">
            Classrooms & Transitions
          </h2>
          <span className="text-xs text-secondaryVariant">(Please review for accuracy)</span>
        </div>
        <div className="grid grid-cols-12 gap-4 bg-[#F2F2F2] p-2 rounded-md">
          <div className="col-span-3 flex items-center justify-between">
            <p className="text-base text-secondary font-semibold">
              Classroom <br />{" "}
              <span className="text-base font-semibold "> Name *</span>
            </p>
            <p className="text-base text-secondary font-semibold max-w-[157px]">
              Classroom has Part-time Sessions?
            </p>
          </div>
          <div className="col-span-7">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <p className="text-base text-secondary font-semibold border-b border-secondaryVariant2 pb-1">
                  Minimum Age *
                </p>
                <div className="grid grid-cols-4 gap-x-3 text-sm text-secondary font-semibold m-0 pt-1">
                  <div>Days</div>
                  <div>Months</div>
                  <div>Years</div>
                  <div className="text-primary">Days</div>
                </div>
              </div>
              <div>
                <p className="text-base text-secondary font-semibold border-b border-secondaryVariant2 pb-1">
                  Maximum Age *
                </p>
                <div className="grid grid-cols-4 gap-x-3 text-sm text-secondary font-semibold m-0 pt-1">
                  <div>Days</div>
                  <div>Months</div>
                  <div>Years</div>
                  <div className="text-primary">Days</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-2">
            <p className="text-base text-secondary font-semibold ">
              Students <br /> Transitions To
            </p>
          </div>
        </div>

        {classrooms?.map((item, index) => {
          const transitionClassroom = classroomOptions.find(
            (option) => option.value === item.transition?.TransitionClassroom
          );

          return (
            <div
              key={index}
              className="grid grid-cols-12 gap-4 px-2 rounded-md"
            >
              <div className="col-span-3">{item.classroomName}</div>
              <div className="col-span-7">
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <div className="grid grid-cols-4 gap-x-3 bg-[#F2F2F2] text-sm text-secondary font-semibold m-0 p-1">
                      <div>{item.ageRange.minimumAge.days}</div>
                      <div>{item.ageRange.minimumAge.months}</div>

                      <div>{item.ageRange.minimumAge.years}</div>
                      <div className={"text-primary"}>
                        {item.ageRange.minimumAge.ageInDays}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="grid grid-cols-4 gap-x-3 bg-[#F2F2F2] text-sm text-secondary font-semibold m-0 p-1">
                      <div>{item.ageRange.maximumAge.days}</div>
                      <div>{item.ageRange.maximumAge.months}</div>

                      <div>{item.ageRange.maximumAge.years}</div>
                      <div className={item.ageRange ? "text-primary" : ""}>
                        {item.ageRange.maximumAge.ageInDays}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-2">
                <div className="flex gap-4">
                  <div className="flex-1">
                    {transitionClassroom?.label ?? "None"}
                  </div>
                  <div className="flex-[0_0_71px]"></div>
                </div>
              </div>
            </div>
          );
        })}
        <div>
          <h2 className="text-2xl font-medium mb-3 flex items-start gap-2">
            Student Transitions
            <span data-tooltip-id={tooltips.studentTransitions.id}>
              <MdInfoOutline className="text-primary w-[18px] h-[18px]" />
            </span>
          </h2>
          <TooltipComponent
            id={tooltips.studentTransitions.id}
            content={tooltips.studentTransitions.content}
            isWhite={true}
          />
          <div className="flex items-center gap-6 bg-secondaryNeutral p-2 rounded">
            <div className="flex items-center gap-2">
              <input
                type="radio"
                className="w-5 h-5 border-2 border-gray-400 appearance-none rounded-full flex items-center justify-center checked:border-primary focus:outline-none checked:bg-white checked:before:content-[''] checked:before:block checked:before:rounded-full checked:before:bg-primary checked:before:w-3 checked:before:h-3 cursor-pointer"
                checked={autoTransition}
                onChange={toggleAutoTransition}
              />
              <label htmlFor="" className="text-base text-secondaryVariant">
                Automatic
              </label>
            </div>
            <div className="flex items-center gap-2">
              <input
                type="radio"
                className="w-5 h-5 border-2 border-gray-400 appearance-none rounded-full flex items-center justify-center checked:border-primary focus:outline-none checked:bg-white checked:before:content-[''] checked:before:block checked:before:rounded-full checked:before:bg-primary checked:before:w-3 checked:before:h-3 cursor-pointer"
                checked={!autoTransition}
                onChange={toggleAutoTransition}
              />
              <label htmlFor="" className="text-base text-secondaryVariant">
                Manual
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EssentialTransition;
