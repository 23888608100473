import React from 'react'
import { Link, useLocation } from "react-router-dom";
import { forayRoutes } from '../../../routes/routes';
import { MdOutlineDashboardCustomize } from 'react-icons/md';
import { RiFileList2Line, RiFocus3Line } from 'react-icons/ri';
import { AiOutlineBars } from 'react-icons/ai';
import { LuFileCheck } from 'react-icons/lu';
import { PiUserCirclePlusLight } from 'react-icons/pi';
import { HiMiniArrowTrendingUp, HiOutlineCircleStack } from 'react-icons/hi2';
import { IoMdBook } from 'react-icons/io';
import { TbUserEdit } from 'react-icons/tb';
import { VscSettings } from 'react-icons/vsc';

const ForayModuleTabs = () => {
    const pathName = useLocation().pathname
    return (
        <div className="flex flex-wrap items-center gap-4">
            <Link
                to={forayRoutes.foray_dashboard}
                className={`group rounded-lg p-3 flex items-center gap-2 hover:bg-primary hover:border-secondary border ${pathName === forayRoutes.foray_dashboard ? "border-secondary bg-primary" : "bg-white border-white "}`}
            >
                <MdOutlineDashboardCustomize className={`w-6 h-6  group-hover:text-white ${pathName === forayRoutes.foray_dashboard ? "text-white" : "text-primary"}`} />
                <span className={`text-lg font-semibold text-truncate line-clamp-1 text-center group-hover:text-white ${pathName === forayRoutes.foray_dashboard ? "text-white" : "text-secondary"}`}>
                    Dashboard
                </span>
            </Link>
            <Link
                to={forayRoutes.enrollment_tracking}
                className="group rounded-lg p-3 flex items-center gap-2 bg-white hover:bg-primary hover:border-secondary border-white border"
            >
                <RiFocus3Line className="w-6 h-6 text-primary group-hover:text-white" />
                <span className="text-lg text-secondary font-semibold text-truncate line-clamp-1 text-center group-hover:text-white">
                    Enrollment Tracking
                </span>
            </Link>
            <Link
                to={forayRoutes.task_generation}
                className="group rounded-lg p-3 flex items-center gap-2 bg-white hover:bg-primary hover:border-secondary border-white border"
            >
                <AiOutlineBars className="w-6 h-6 text-primary group-hover:text-white" />
                <span className="text-lg text-secondary font-semibold text-truncate line-clamp-1 text-center group-hover:text-white">
                    Task Generation
                </span>
            </Link>
            <Link
                to={forayRoutes.checkList}
                className="group rounded-lg p-3 flex items-center gap-2 bg-white hover:bg-primary hover:border-secondary border-white border">
                <LuFileCheck className="w-6 h-6 text-primary group-hover:text-white" />
                <span className="text-lg text-secondary font-semibold text-truncate line-clamp-1 text-center group-hover:text-white">
                    Checklist
                </span>
            </Link>
            <Link
                to={forayRoutes.hr_dashboard}
                className="group rounded-lg p-3 flex items-center gap-2 bg-white hover:bg-primary hover:border-secondary border-white border"
            >
                <PiUserCirclePlusLight className="w-6 h-6 text-primary group-hover:text-white" />
                <span className="text-lg text-secondary font-semibold text-truncate line-clamp-1 text-center group-hover:text-white">
                    HR/Payroll
                </span>
            </Link>
            <div className="group rounded-lg p-3 flex items-center gap-2 bg-white hover:bg-primary hover:border-secondary border-white border">
                <HiOutlineCircleStack className="w-6 h-6 text-primary group-hover:text-white" />
                <span className="text-lg text-secondary font-semibold text-truncate line-clamp-1 text-center group-hover:text-white">
                    Financial {" "}
                </span>
            </div>
            <div className="group rounded-lg p-3 flex items-center gap-2 bg-white hover:bg-primary hover:border-secondary border-white border">
                <IoMdBook className="w-6 h-6 text-primary group-hover:text-white" />
                <span className="text-lg text-secondary font-semibold text-truncate line-clamp-1 text-center group-hover:text-white">
                    Handbooks/Policy
                </span>
            </div>
            <div className="group rounded-lg p-3 flex items-center gap-2 bg-white hover:bg-primary hover:border-secondary border-white border">
                <RiFileList2Line className="w-6 h-6 text-primary group-hover:text-white" />
                <span className="text-lg text-secondary font-semibold text-truncate line-clamp-1 text-center group-hover:text-white">
                    Kid Reports
                </span>
            </div>
            <div className="group rounded-lg p-3 flex items-center gap-2 bg-white hover:bg-primary hover:border-secondary border-white border">
                <HiMiniArrowTrendingUp className="w-6 h-6 text-primary group-hover:text-white" />
                <span className="text-lg text-secondary font-semibold text-truncate line-clamp-1 text-center group-hover:text-white">
                    QI/Trends {" "}
                </span>
            </div>
            <div
                // to={forayRoutes.task_generation}
                className="group rounded-lg p-3 flex items-center gap-2 bg-white hover:bg-primary hover:border-secondary border-white border"
            >
                <TbUserEdit className="6 h-6 text-primary group-hover:text-white" />

                <span className="text-lg text-secondary font-semibold text-truncate line-clamp-1 text-center group-hover:text-white">
                    Family View
                </span>
            </div>
            {/* <Link
                        to={forayRoutes.center_view}
                        className="group rounded-lg p-3 flex items-center gap-2 bg-white hover:bg-primary hover:border-secondary border-white border"
                      >
                        <MdOutlineDashboardCustomize className="w-6 h-6 text-primary group-hover:text-white" />
                        <span className="text-lg text-secondary font-semibold text-truncate line-clamp-1 text-center group-hover:text-white">
                          Center Setup
                        </span>
                      </Link> */}

            <Link
                to={forayRoutes.center_view}
                className="group rounded-lg p-3 flex items-center gap-2 bg-white hover:bg-primary hover:border-secondary border-white border"
            >
                <VscSettings className="w-6 h-6 text-primary group-hover:text-white" />
                <span className="text-lg text-secondary font-semibold text-truncate line-clamp-1 text-center group-hover:text-white">
                    System Setup
                </span>
            </Link>
        </div>
    )
}

export default ForayModuleTabs