import { ApiErrorResponse, create } from "apisauce";
import { apiBaseUrlUsers } from "../../shared/constants/environment.constants";
import { STORAGE_KEYS } from "../../shared/enums/sharedEnums";
import spinnerSvc from "../../shared/utils/spinner-service";
import { refreshToken, signOut } from "../../shared/services/auth.service";


let isRefreshing = false;
let waitingQueue: any[] = [];

if (!apiBaseUrlUsers) {
  throw new Error("No Api apiBaseUrl");
}


export const apiSuperAdmin = create({
  baseURL: apiBaseUrlUsers,
  headers: { Accept: "application/json"},
})

const processQueue = (error: ApiErrorResponse<any> | null, token = null) => {
  waitingQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  isRefreshing = false;
  waitingQueue = [];
};

apiSuperAdmin.axiosInstance.interceptors.request.use(
  async (config: any) => {
    const accessToken = localStorage.getItem(STORAGE_KEYS?.ACC_TOKEN);
    if (accessToken !== "") {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    spinnerSvc.start();
    return config;
  },

  (err) => console.error(err)
);

apiSuperAdmin.axiosInstance.interceptors.response.use(
  (response: any) => {
    spinnerSvc.stop();
    return response;
  },

  async (err) => {
    spinnerSvc.stop();
    const originalRequest = err.config;

    if (err.response?.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          waitingQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = "Bearer " + token;
            return apiSuperAdmin.axiosInstance(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }
      originalRequest._retry = true;
      isRefreshing = true;

      const accessToken = await refreshToken();
      if (accessToken) {
        apiSuperAdmin.axiosInstance.defaults.headers.common["Authorization"] =
          "Bearer " + accessToken;
        processQueue(null, accessToken);
        return apiSuperAdmin.axiosInstance(originalRequest);
      } else {
        spinnerSvc.stop();
        signOut();
        // store.dispatch(removeUser());
        return;
      }
    }
    console.error(err.response.data);

    switch (err.response?.status) {
      case 400:
        console.error(err);

        spinnerSvc.stop();

        return err;

      case 401:
        console.error(err);
        signOut();
        isRefreshing = false;
        spinnerSvc.stop();

        return err;

      case 404:
        console.error(err);

        spinnerSvc.stop();

        return err;

      case 500:
        console.error(err);

        spinnerSvc.stop();

        return err;

      default:
        console.error(err);

        spinnerSvc.stop();

        return err;
    }
  }
);
