import { Filters } from "../types/IDashboard.Interface";
import { apiSuperAdmin } from "../utils/axios";

const fetchDashboard = async (filters: Filters) => {
  try {
    const params = new URLSearchParams();
    params.append("view", filters.view);
    if (filters.startDate) params.append("startDate", filters.startDate);
    if (filters.endDate) params.append("endDate", filters.endDate);

    const res = await apiSuperAdmin.get<any>(
      `/super-admin?${params.toString()}`
    );
    if (res?.ok) {
      return { data: res?.data, ok: true };
    }
    return {
      ok: false,
      error: res?.data,
    };
  } catch (err) {
    console.log("error: ", err);
    return {
      ok: false,
      error: err,
    };
  }
};

export const superAdminSvc = {
  fetchDashboard,
};
