import { useQuery } from "@tanstack/react-query";
import { StripeQueryKeyString } from "../enums/stripeQueryKeyString";
import { stripeSvc } from "../services/stripe.service";

export const useSubscription = (subscriptionId?: string) => {
  return useQuery({
    queryKey: [StripeQueryKeyString.Subscription,subscriptionId],
    queryFn: () => stripeSvc.fetchSubsciption(subscriptionId!),
    enabled: !!subscriptionId
  });
};
