import React from "react";
import DashboardWrapper from "../../../shared/components/layout/DashboardWrapper";
import { SlLockOpen } from "react-icons/sl";
import { Link } from "react-router-dom";
import { forayRoutes } from "../../routes/routes";
import { MdOutlineDashboardCustomize } from "react-icons/md";
import { RiFocus3Line } from "react-icons/ri";
import { PiUserCirclePlusLight } from "react-icons/pi";
import { AiOutlineBars } from "react-icons/ai";
import { HiOutlineCircleStack } from "react-icons/hi2";
import { RiFileList2Line } from "react-icons/ri";
import { HiMiniArrowTrendingUp } from "react-icons/hi2";
import { TbUserEdit } from "react-icons/tb";
import { VscSettings } from "react-icons/vsc";
import { LuFileCheck } from "react-icons/lu";
import { IoMdBook } from "react-icons/io";
import ForayModuleTabs from "./components/ForayModuleTabs";

const ForayScreen = () => {
  return (
    <>
      <DashboardWrapper>
        <div className="pt-5 px-5 flex items-center justify-center">
          <div className="max-w-full">
            <h2 className="text-2xl text-secondary font-semibold mb-6">
              Foray
            </h2>
            <p className="text-lg text-secondaryVariant mb-10">
              Most <strong className="text-primary italic">Forward!</strong>{" "}
              Looking Software… it’s so Advanced, it’s simple.
            </p>
            <ForayModuleTabs />
          </div>
        </div>
      </DashboardWrapper >
    </>
  );
};

export default ForayScreen;
