import { Nav, Tab } from "react-bootstrap";
import { IoDocumentAttachOutline } from "react-icons/io5";
import { PiBriefcaseLight, PiCertificate } from "react-icons/pi";
import { RiUser6Line } from "react-icons/ri";
import { useLocation } from "react-router-dom";
import DashboardWrapper from "../../../shared/components/layout/DashboardWrapper";
import Breadcrumb from "../../components/Breadcrumb";
import EducationNotification from "../../components/fillOutInformation/Education&Certification";
import EmployeeInformationForm from "../../components/fillOutInformation/EmployeeInformationForm";
import ProfessionalInformation from "../../components/fillOutInformation/ProfessionalInformation";
import { IEmployeeInfo } from "../../types/IStaff";
import { useState } from "react";
import { EditType } from "../../enums/editType";
import UploadEmployeeDocuments from "../../components/fillOutInformation/UploadEmployeeDocuments";

const EmployeePersonalInformation = () => {
  const breadcrumbItems = [
    { label: "Foray", link: "/" },
    { label: "Human Resource Management", link: "/" },
    { label: "Employee Setup", isCurrent: true },
  ];

  const { state } = useLocation();
  const employee =
    (state as { employee?: IEmployeeInfo })?.employee ?? undefined;
  const type = (state as { type?: string })?.type ?? null;
  const [isPersonalInfoComplete,setIsPersonalInfoComplete]=useState(false)
  const [isEducationComplete,setIsEducationComplete]=useState(false)
  const [isProfessionalInfoComplete,setIsProfessionalInfoComplete]=useState(false)
  const [isDocumentComplete,setIsDocumentComplete]=useState(false)

  // const [activeKey, setActiveKey] = useState<string>(type);

  // console.log('editType',editType)
  // console.log('state',state)
  return (
    <>
      <DashboardWrapper>
        <div className="px-6 py-6">
          <div className="space-y-6">
            <Breadcrumb items={breadcrumbItems} />
            <h4 className=" text-secondary font-semibold text-xl">
              Add Employee
            </h4>
            <Tab.Container
              id="left-tabs-example"
              defaultActiveKey={type ?? EditType.personalInfo}
            >
              <Nav
                variant="pills"
                className="flex items-center border-b border-secondaryVariant2 mb-6"
              >
                <Nav.Item>
                  <Nav.Link
                    eventKey={EditType.personalInfo}
                    className="inline-flex items-center gap-2 ps-0 px-3 pb-3 text-lg [&.active]:text-primary [&.active]:font-medium border-b-[2px] border-transparent [&.active]:border-primary hover:text-primary hover:border-primary"
                  >
                    <RiUser6Line className="w-6 h-6" />
                    Personal Information
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                  // disabled={!isPersonalInfoComplete}
                    eventKey={EditType.education}
                    className="inline-flex items-center gap-2 px-3 pb-3 text-lg [&.active]:text-primary [&.active]:font-medium border-b-[2px] border-transparent [&.active]:border-primary hover:text-primary hover:border-primary"
                  >
                    <PiCertificate className="w-6 h-6" />
                    Education & Certification
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey={EditType.professionalInfo}
                    className="inline-flex items-center gap-2 px-3 pb-3 text-lg [&.active]:text-primary [&.active]:font-medium border-b-[2px] border-transparent [&.active]:border-primary hover:text-primary hover:border-primary"
                  >
                    <PiBriefcaseLight className="w-6 h-6" />
                    Professional Information
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey={EditType.documents}
                    className="inline-flex items-center gap-2 px-3 pb-3 text-lg [&.active]:text-primary [&.active]:font-medium border-b-[2px] border-transparent [&.active]:border-primary hover:text-primary hover:border-primary"
                  >
                    <IoDocumentAttachOutline className="w-6 h-6" />
                    Documents
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <div className="overflow-y-scroll h-[calc(100vh-295px)] custom-scrollbar">
                  <Tab.Pane
                    eventKey={EditType.personalInfo}
                    className="hidden opacity-0 [&.show]:block [&.show]:opacity-100  duration-300 transition-opacity ease-linear"
                  >
                    <EmployeeInformationForm />
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey={EditType.education}
                    className="hidden opacity-0 [&.show]:block [&.show]:opacity-100 duration-300 transition-opacity ease-linear"
                  >
                    <EducationNotification employeeData={employee} />
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey={EditType.professionalInfo}
                    className="hidden opacity-0 [&.show]:block [&.show]:opacity-100 duration-300 transition-opacity ease-linear"
                  >
                    <ProfessionalInformation />{" "}
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey={EditType.documents}
                    className="hidden opacity-0 [&.show]:block [&.show]:opacity-100 duration-300 transition-opacity ease-linear"
                  >
                    <UploadEmployeeDocuments />
                  </Tab.Pane>
                </div>
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>
      </DashboardWrapper>
    </>
  );
};

export default EmployeePersonalInformation;
