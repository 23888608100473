import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { stripePublishKey } from "../../constants/environment.constants";

const stripePromise = loadStripe(stripePublishKey);

interface IProps {
  fetchClientSecret: () => Promise<string>;
}

const StripePricingTable: React.FC<IProps> = ({ fetchClientSecret }) => {
  return (
    <EmbeddedCheckoutProvider
      stripe={stripePromise}
      options={{ fetchClientSecret }}
    >
      <EmbeddedCheckout />
    </EmbeddedCheckoutProvider>
  );
};

export default StripePricingTable;
